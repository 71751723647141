import React, { useState } from "react";
import {
  Flex,
  Card,
  Heading,
  Text,
  TextField,
  TextAreaField,
  View,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import { createLead } from "../../graphql/mutations";

import {
  ContactBg,
  ContactImg,
  ContactContent,
  ContactHeaderWrap,
  ContactH1,
  ContactH2,
  ContactMark,
  ContactContainer,
} from "./ContactElements";
import { ButtonForm, ButtonShowEmail } from "../ButtonElements";
import { COLORS } from "../../constants";

const ContactSection = ({
  heading,
  subtitle,
  emailButtonText,
  emailMark,
  form,
}) => {
  const { tokens } = useTheme();
  const [confirmation, setConfirmation] = useState(false);
  const [displayEmail, setDisplayAdress] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    await API.graphql({
      query: createLead,
      variables: {
        input: {
          name,
          email,
          message,
        },
      },
    });
    e.target.reset();
    setConfirmation(true);
  };

  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({ top: 0, left: 0 });
  // }, []);

  return (
    <ContactContainer id="home">
      <ContactBg>
        <ContactImg
          src={require("../../images/ContactCover.png")}
          alt=""
        ></ContactImg>
      </ContactBg>
      <ContactContent>
        <ContactHeaderWrap>
          <ContactH1>{heading}</ContactH1>
          <ContactH2>{subtitle}</ContactH2>
          <ButtonShowEmail
            buttonLabel={emailButtonText}
            onClick={() => setDisplayAdress(true)}
            id="show-email-button"
          />
          <ContactMark display={displayEmail ? 1 : 0}>{emailMark}</ContactMark>
        </ContactHeaderWrap>

        <Card
          padding={{ large: tokens.space.xxxl }}
          variation="elevated"
          borderRadius={tokens.radii.medium}
          // backgroundColor={tokens.colors.blue[90]}
          // backgroundColor={COLORS.dark}
          backgroundColor="rgba(1,1,6, 0.3)"
        >
          <Flex
            direction={{ base: "column", large: "row" }}
            justifyContent={{ large: "center" }}
            gap={tokens.space.xl}
          >
            <Flex direction={"column"} justifyContent="space-between">
              <View
                style={{ marginBottom: tokens.space.small }}
                width={{ base: "70vw", large: "300px" }}
              >
                <Heading color={tokens.colors.white} level={3}>
                  {form.title}
                </Heading>
                {!confirmation ? (
                  <Text color={tokens.colors.white}>{form.fillMessage}</Text>
                ) : null}
                {confirmation ? (
                  <Text color={COLORS.lilac}>{form.confirmationMessage}</Text>
                ) : null}
              </View>
              +{" "}
            </Flex>
            <View
              width={{ base: "70vw", large: "400px" }}
              backgroundColor={COLORS.lightgrey}
              padding={tokens.space.medium}
              borderRadius={tokens.radii.medium}
            >
              <Flex as="form" direction={"column"} onSubmit={handleFormSubmit}>
                <TextField
                  required
                  label={form.nameLabel}
                  name="name"
                  placeholder={form.namePlaceholder}
                />
                <TextField
                  label={form.emailLabel}
                  name="email"
                  placeholder={form.emailPlaceholder}
                  type={"email"}
                  required
                />
                <TextAreaField
                  required
                  label={form.messageLabel}
                  name="message"
                  placeholder={form.messagePlaceholder}
                />
                <View
                  style={{ marginTop: tokens.space.medium, display: "flex" }}
                  justifyContent="flex-end"
                >
                  <ButtonForm buttonLabel={form.buttonLabel} />
                </View>
              </Flex>
            </View>
          </Flex>
        </Card>
      </ContactContent>
    </ContactContainer>
  );
};

export default ContactSection;
