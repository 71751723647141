import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  FooterImgLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

const Footer = ({ sections, copyrights }) => {
  const toggleTop = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{sections.col1.title}</FooterLinkTitle>
              <FooterLink
                to={sections.col1.howWeWork.to}
                smooth={true}
                duration={500}
              >
                {sections.col1.howWeWork.name}
              </FooterLink>
              <FooterLink
                to={sections.col1.careers.to}
                smooth={true}
                duration={500}
              >
                {sections.col1.careers.name}
              </FooterLink>
              <FooterLink
                to={sections.col1.privacy.to}
                smooth={true}
                duration={500}
              >
                {sections.col1.privacy.name}
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>{sections.col2.title}</FooterLinkTitle>
              <FooterLink
                to={sections.col2.contact.to}
                smooth={true}
                duration={500}
              >
                {sections.col2.contact.name}
              </FooterLink>
              <FooterLink to={sections.col2.intro.to} target="_blank">
                {sections.col2.intro.name}
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{sections.col3.title}</FooterLinkTitle>
              <FooterLink to={sections.col3.linkedin.to} target="_blank">
                {sections.col3.linkedin.name}
              </FooterLink>
              <FooterLink to={sections.col3.instagram.to} target="_blank">
                {sections.col3.instagram.name}
              </FooterLink>
              <FooterLink to={sections.col3.facebook.to} target="_blank">
                {sections.col3.facebook.name}
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleTop}>
              <FooterImgLogo
                src={require("../../images/Logotype Blanc.svg").default}
                alt={"Yucca lab"}
              />
            </SocialLogo>

            <WebsiteRights>
              {copyrights.text1}
              {new Date().getFullYear()}
              {copyrights.text2}
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//www.linkedin.com/company/73061624/"
                target="_blank"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink
                href="//www.instagram.com/yuccalab/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>

              <SocialIconLink
                href="//www.facebook.com/yuccalab"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
