export const COLORS = {
  deepblue: "#121B3A",
  lightgrey: "#F5F5FD",
  cornflowerblue: "#6A9AF8",
  bdazzledblue: "#405DA0",
  royalblue: "#518cff",
  turquoise: "#12C9BE",
  coral: "#FF8754",
  lilac: "#D1B1FF",
  dark: "#010606",
  white: "#fff",
  undefined: "#101522",
};
