import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import HeroFooterSection from "../components/HeroFooterSection";
import InfoSection from "../components/InfoSection";
import Footer from "../components/Footer";
import Products from "../components/Products";
import Referrals from "../components/ReferralsSection";
import getLocalizedTexts from "../utils/getLocalizedTexts";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const img = () => {
    return require("../images/Yuccalab-logo.svg").default;
  };

  const texts = getLocalizedTexts();
  const menuText = texts.menu;
  const heroObj = texts.home.heroSection;
  const heroTitleObj = texts.home.heroFooterSection;
  const homeObjAgency = texts.home.agencySection;
  const homeObjServicesText = texts.home.servicesSection.textColumn;
  const homeObjImpact = texts.home.impactSection;
  const homeObjAbout = texts.home.aboutSection;
  const ReferralsObj = texts.home.referralsSection.title;
  const homeObjContact = texts.home.contactSection;
  const FooterObj = texts.footer;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.yuccalab.fr" />
      </Helmet>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        isRoot={true}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <Navbar
        toggle={toggle}
        img={img}
        smoothOpacity={true}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <HeroSection {...heroObj} />
      <HeroFooterSection {...heroTitleObj} />
      <InfoSection {...homeObjAgency} />
      <Products {...homeObjServicesText} />
      <InfoSection {...homeObjImpact} />
      <InfoSection {...homeObjAbout} />
      <Referrals {...ReferralsObj} />
      <InfoSection {...homeObjContact} />
      <Footer {...FooterObj} />
    </>
  );
};

export default Home;
