import React from "react";

import {
  IconCalloutContainer,
  IconCalloutWrapper,
  IconCalloutIcon,
  IconCalloutImg,
  IconCalloutText,
  IconCalloutParagraph,
} from "./IconCalloutElements";

const IconCallout = ({ lightBg, img, alt, text }) => {
  return (
    <IconCalloutContainer lightBg={lightBg}>
      <IconCalloutWrapper>
        <IconCalloutIcon>
          <IconCalloutImg src={img} alt={alt} />
        </IconCalloutIcon>
        <IconCalloutText>
          <IconCalloutParagraph>{text}</IconCalloutParagraph>
        </IconCalloutText>
      </IconCalloutWrapper>
    </IconCalloutContainer>
  );
};

export default IconCallout;
