import React, { useState } from "react";
import { DropdownLink, NavDropdown, NavLinks } from "./NavbarElements";

const ServicesDropdown = ({ servicesSectionName }) => {
  const [dropdown, setDropdown] = useState(false);
  const onMouseEnter = () => {
    setDropdown(true);
  };
  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <NavDropdown onMouseLeave={onMouseLeave}>
      <NavLinks
        to="/services/#"
        smooth={true}
        duration={500}
        spy="true"
        exact="true"
        offset={-80}
        onMouseEnter={onMouseEnter}
      >
        {servicesSectionName.name}
      </NavLinks>
      <DropdownLink dropdown={dropdown}>
        <NavLinks
          to="/services/design/#"
          smooth={true}
          duration={500}
          spy="true"
          exact="true"
          offset={-80}
        >
          {servicesSectionName.dropdown.design}
        </NavLinks>
      </DropdownLink>
      <DropdownLink dropdown={dropdown}>
        <NavLinks
          to="/services/engineering/#"
          smooth={true}
          duration={500}
          spy="true"
          exact="true"
          offset={-80}
        >
          {servicesSectionName.dropdown.engineering}
        </NavLinks>
      </DropdownLink>
      <DropdownLink dropdown={dropdown}>
        <NavLinks
          to="/services/prototyping/#"
          smooth={true}
          duration={500}
          spy="true"
          exact="true"
          offset={-80}
        >
          {servicesSectionName.dropdown.prototyping}
        </NavLinks>
      </DropdownLink>
      <DropdownLink dropdown={dropdown}>
        <NavLinks
          to="/services/manufacturing/#"
          smooth={true}
          duration={500}
          spy="true"
          exact="true"
          offset={-80}
        >
          {servicesSectionName.dropdown.manufacturing}
        </NavLinks>
      </DropdownLink>
    </NavDropdown>
  );
};

export default ServicesDropdown;
