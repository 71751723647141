import React, { useState } from "react";
import { ButtonRedirect } from "../ButtonElements";
import {
  ServicesFooterContainer,
  ServicesFooterContent,
  ServicesFooterTitle,
  ServicesFooterSubtitle,
  ServicesFooterBtnWrapper,
} from "./ServicesFooterElements";

const ServicesFooterSection = ({ title, subtitle, buttonLabel, to }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <ServicesFooterContainer>
      <ServicesFooterContent>
        <ServicesFooterTitle>{title}</ServicesFooterTitle>
        <ServicesFooterSubtitle>{subtitle}</ServicesFooterSubtitle>
        <ServicesFooterBtnWrapper>
          <ButtonRedirect
            to={to}
            target="_blank"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary={1}
            dark={0}
          >
            {buttonLabel}
          </ButtonRedirect>
        </ServicesFooterBtnWrapper>
      </ServicesFooterContent>
    </ServicesFooterContainer>
  );
};

export default ServicesFooterSection;
