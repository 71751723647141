import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { COLORS } from "../../constants";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLanSelect,
  NavLinks,
  NavLanLink,
  NavBtn,
  NavBtnLink,
  NavImgLogo,
} from "./NavbarElements";
import ServicesDropdown from "./ServicesDropdown";

const Navbar = ({
  toggle,
  img,
  smoothOpacity,
  home,
  sectors,
  services,
  agency,
  contact,
}) => {
  const [navOpacity, setNavOpacity] = useState(0);

  const changeNavOpacity = () => {
    setNavOpacity(Math.min(window.scrollY / 80, 1));
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavOpacity);
    return () => {
      window.removeEventListener("scroll", changeNavOpacity);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: COLORS.turquoise }}>
        <Nav navOpacity={smoothOpacity ? navOpacity : 1}>
          <NavbarContainer>
            <NavLogo to="/#" smooth={true} duration={500}>
              <NavImgLogo
                src={require("../../images/Logotype Blanc Couleur.svg").default}
                alt={"Yucca lab"}
              />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to={home.to}
                  smooth={true}
                  duration={500}
                  spy="true"
                  exact="true"
                  offset={-80}
                >
                  {home.name}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to={sectors.to}
                  smooth={true}
                  duration={500}
                  spy="true"
                  exact="true"
                  offset={-80}
                >
                  {sectors.name}
                </NavLinks>
              </NavItem>
              <ServicesDropdown servicesSectionName={services} />
              <NavItem>
                <NavLinks
                  to={agency.to}
                  smooth={true}
                  duration={500}
                  spy="true"
                  exact="true"
                  offset={-80}
                >
                  {agency.name}
                </NavLinks>
              </NavItem>

              <NavbarLan />
            </NavMenu>
            <NavBtn>
              <NavBtnLink
                to={contact.to}
                smooth={true}
                duration={500}
                spy="true"
                exact="true"
              >
                {contact.name}
              </NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

const NavbarLan = () => {
  return (
    <NavLanSelect>
      <NavLanLink
        to="/"
        onClick={() => {
          localStorage.yuccalabLanguage = "en";
          window.location.reload(true);
        }}
      >
        EN
      </NavLanLink>
      <div>/</div>
      <NavLanLink
        to="/"
        onClick={() => {
          localStorage.yuccalabLanguage = "fr";
          window.location.reload(true);
        }}
      >
        FR
      </NavLanLink>
    </NavLanSelect>
  );
};

export default Navbar;
