import React from "react";
// import { MdBluetoothSearching } from "react-icons/md";
import { ButtonRedirect, ButtonScroll } from "../ButtonElements";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  ItemsWrap,
  Item,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headLine,
  darkText,
  darkItems,
  description,
  description2,
  description3,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  redirect,
  to,
  tight,
  tightLayout,
  items,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id} tight={tight}>
        <InfoWrapper tightLayout={tightLayout}>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                {description2 ? (
                  <Subtitle darkText={darkText}>{description2}</Subtitle>
                ) : null}
                {description3 ? (
                  <Subtitle darkText={darkText}>{description3}</Subtitle>
                ) : null}
                {items ? (
                  <ItemsWrap darkText={darkText}>
                    {items.map((item, idx) => (
                      <Item darkItems={darkItems} key={id + "-" + idx}>
                        {item}
                      </Item>
                    ))}
                  </ItemsWrap>
                ) : null}
                {buttonLabel ? (
                  <BtnWrap>
                    {redirect ? (
                      <ButtonRedirect
                        to={to}
                        duration={500}
                        spy={"true"}
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </ButtonRedirect>
                    ) : (
                      <ButtonScroll
                        to={to}
                        duration={500}
                        spy={true}
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </ButtonScroll>
                    )}
                  </BtnWrap>
                ) : null}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
