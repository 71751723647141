import styled from "styled-components";
import { COLORS } from "../../constants";

export const FeaturesSectionContainer = styled.div`
  background-color: ${COLORS.lightgrey};
  margin-bottom: 30px;
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FeaturesSectionWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const FeatureCardContainer = styled.div`
  background-color: ${COLORS.deepblue};
  color: ${COLORS.white};
  border-radius: 25px;
  max-width: 390px;
  width: 30%;
  margin: 30px;
  display: flex;
  @media screen and (max-width: 840px) {
    margin: 15px;
  }
  @media screen and (max-width: 640px) {
    justify-content: center;
    width: 100%;
  }
`;

export const FeatureCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FeatureCardTitle = styled.h2`
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  margin: 30px 5px;
  @media screen and (max-width: 840px) {
    font-size: 18px;
  }
`;

export const FeatureCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FeatureCardBtnWrap = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const FeatureCardItemContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const FeatureCardItemIcon = styled.div`
  margin: 10px 20px;
`;

export const FeatureCardItemIconImg = styled.img`
  width: 75px;
  @media screen and (max-width: 840px) {
    width: 40px;
  }
`;

export const FeatureCardItemTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
`;

export const FeatureCardItemHeading = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  @media screen and (max-width: 840px) {
    font-size: 12px;
  }
`;

export const FeatureCardItemSubtitle = styled.p`
  font-size: 16px;
  @media screen and (max-width: 840px) {
    font-size: 12px;
  }
`;
