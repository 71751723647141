import React, { useState } from "react";
import {
  ProductsContainer,
  ProductsImgGrid,
  ProductsRow,
  ProductsImgWrapper,
  ProductsHashLink,
  ProductsLinkWrapper,
  ProductsImg,
  ProductsTextWrapper,
  ProductsImagesWrapper,
  ProductsHeading,
  ProductsSubtitle,
  ProductImgBox,
} from "./ProductsElements";
import ProductsLegend from "./ProductsLegend";
import { TopLine, BtnWrap } from "../InfoSection/InfoElements";
import { ButtonRedirect, ButtonScroll } from "../ButtonElements";
import getLocalizedTexts from "../../utils/getLocalizedTexts";

const Products = ({
  lightBg,
  id,
  topLine,
  lightText,
  headLine,
  darkText,
  description,
  buttonLabel,
  redirect,
  to,
  primary,
  dark,
}) => {
  const [hover1, setHover1] = useState(false);
  const onHoverEnter1 = () => {
    setHover1(true);
  };
  const onHoverLeave1 = () => {
    setHover1(false);
  };
  const [hover2, setHover2] = useState(false);
  const onHoverEnter2 = () => {
    setHover2(true);
  };
  const onHoverLeave2 = () => {
    setHover2(false);
  };
  const [hover3, setHover3] = useState(false);
  const onHoverEnter3 = () => {
    setHover3(true);
  };
  const onHoverLeave3 = () => {
    setHover3(false);
  };

  const texts = getLocalizedTexts();
  const servicesColumn = texts.home.servicesSection.servicesColumn;
  const servicesServiceOne = servicesColumn.serviceOne;
  const servicesServiceTwo = servicesColumn.serviceTwo;
  const servicesServiceThree = servicesColumn.serviceThree;

  return (
    <>
      <ProductsContainer lightBg={lightBg} id={id}>
        <ProductsImgGrid>
          <ProductsRow>
            <ProductsTextWrapper>
              <TopLine>{topLine}</TopLine>
              <ProductsHeading lightText={lightText} uppercase={true}>
                {headLine}
              </ProductsHeading>
              <ProductsSubtitle darkText={darkText}>
                {description}
              </ProductsSubtitle>
              {buttonLabel ? (
                <BtnWrap>
                  {redirect ? (
                    <ButtonRedirect
                      to={to}
                      smooth="true"
                      duration={500}
                      spy={"true"}
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                    >
                      {buttonLabel}
                    </ButtonRedirect>
                  ) : (
                    <ButtonScroll
                      to={to}
                      duration={500}
                      spy={true}
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                    >
                      {buttonLabel}
                    </ButtonScroll>
                  )}
                </BtnWrap>
              ) : null}
            </ProductsTextWrapper>
            <ProductsImagesWrapper darkText={darkText}>
              <ProductsImgWrapper
                onMouseEnter={onHoverEnter1}
                onMouseLeave={onHoverLeave1}
              >
                <ProductsHashLink
                  to={servicesServiceOne.to}
                  smooth="true"
                  duration={500}
                >
                  <ProductsLinkWrapper imgStart={servicesServiceOne.imgStart}>
                    <ProductImgBox>
                      <ProductsImg
                        src={servicesServiceOne.img}
                        alt={servicesServiceOne.alt}
                      />
                    </ProductImgBox>
                    <ProductsLegend
                      hover={hover1}
                      darkText={darkText}
                      {...servicesServiceOne}
                    ></ProductsLegend>
                  </ProductsLinkWrapper>
                </ProductsHashLink>
              </ProductsImgWrapper>
              <ProductsImgWrapper
                onMouseEnter={onHoverEnter2}
                onMouseLeave={onHoverLeave2}
              >
                <ProductsHashLink
                  to={servicesServiceTwo.to}
                  smooth="true"
                  duration={500}
                >
                  <ProductsLinkWrapper imgStart={servicesServiceTwo.imgStart}>
                    <ProductImgBox>
                      <ProductsImg
                        src={servicesServiceTwo.img}
                        alt={servicesServiceTwo.alt}
                      />
                    </ProductImgBox>
                    <ProductsLegend
                      hover={hover2}
                      darkText={darkText}
                      {...servicesServiceTwo}
                    ></ProductsLegend>
                  </ProductsLinkWrapper>
                </ProductsHashLink>
              </ProductsImgWrapper>
              <ProductsImgWrapper
                onMouseEnter={onHoverEnter3}
                onMouseLeave={onHoverLeave3}
              >
                <ProductsHashLink
                  to={servicesServiceThree.to}
                  smooth="true"
                  duration={500}
                >
                  <ProductsLinkWrapper imgStart={servicesServiceThree.imgStart}>
                    <ProductImgBox>
                      <ProductsImg
                        src={servicesServiceThree.img}
                        alt={servicesServiceThree.alt}
                      />
                    </ProductImgBox>
                    <ProductsLegend
                      hover={hover3}
                      darkText={darkText}
                      {...servicesServiceThree}
                    ></ProductsLegend>
                  </ProductsLinkWrapper>
                </ProductsHashLink>
              </ProductsImgWrapper>
            </ProductsImagesWrapper>
          </ProductsRow>
        </ProductsImgGrid>
      </ProductsContainer>
    </>
  );
};

export default Products;
