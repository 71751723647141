import React from "react";

import {
  HeadlineBannerContainer,
  HeadlineBannerBg,
  HeadlineBannerImg,
  HeadlineBannerContent,
  HeadlineBannerH2,
  HeadlineBannerH3,
} from "./HeadlineBannerElements";

const HeadlineBanner = ({ id, title, subtitle, img }) => {
  return (
    <HeadlineBannerContainer id={id}>
      <HeadlineBannerBg>
        <HeadlineBannerImg src={img} alt="" />
      </HeadlineBannerBg>

      <HeadlineBannerContent>
        <HeadlineBannerH2>{title}</HeadlineBannerH2>
        <HeadlineBannerH3>{subtitle}</HeadlineBannerH3>
      </HeadlineBannerContent>
    </HeadlineBannerContainer>
  );
};

export default HeadlineBanner;
