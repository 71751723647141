import React from "react";
import {
  ProductsLegendWrapper,
  ProductsH3,
  ProductsP,
} from "./ProductsElements";

const ProductsLegend = ({ hover, darkText, title, description, imgStart }) => {
  return (
    <ProductsLegendWrapper imgStart={imgStart}>
      <ProductsH3 hover={hover} darkText={darkText}>
        {title}
      </ProductsH3>
      <ProductsP>{description}</ProductsP>
    </ProductsLegendWrapper>
  );
};

export default ProductsLegend;
