import React, { useState } from "react";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import getLocalizedTexts from "../utils/getLocalizedTexts";
import { Helmet } from "react-helmet";

const Privacy = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const img = () => {
    return require("../images/Yuccalab-logo.svg").default;
  };

  const texts = getLocalizedTexts();
  const menuText = texts.menu;
  const FooterObj = texts.footer;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.yuccalab.fr/privacypolicy" />
      </Helmet>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <Navbar
        toggle={toggle}
        img={img}
        smoothOpacity={false}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <PrivacyPolicy />
      <Footer {...FooterObj} />
    </>
  );
};

export default Privacy;
