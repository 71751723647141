import styled from "styled-components";
import { COLORS } from "../../constants";

export const ContactContainer = styled.div`
  background: ${COLORS.deepblue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 1200px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    background: rgba(18, 27, 58, 0.2);
    z-index: 2;
  }
`;

export const ContactBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const ContactImg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const ContactContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactHeaderWrap = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ContactH1 = styled.h1`
  color: ${COLORS.lightgrey};
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ContactH2 = styled.p`
  margin-top: 24px;
  margin-bottom: 48px;
  color: ${COLORS.lightgrey};
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const ContactMark = styled.mark`
  color: ${({ display }) => (display ? COLORS.lightgrey : "transparent")};
  margin-top: 24px;
  font-size: 24px;
  text-align: center;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    ${({ display }) => (display ? COLORS.bdazzledblue : "transparent")} 50%
  );
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const ContactFormWrapper = styled.div`
  margin-top: 50px;
  color: ${COLORS.lightgrey};
`;

export const ContactForm = styled.form``;

export const ContactH3 = styled.h3``;

export const FormInput = styled.input``;

export const FormTextInput = styled.input``;

export const Result = styled.p``;
