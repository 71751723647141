import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages";
import Contact from "./pages/contact";
import Privacy from "./pages/privacy";
import Services from "./pages/services";
import Design from "./pages/services/design";
import Careers from "./pages/careers";
import Engineering from "./pages/services/engineering";
import Prototyping from "./pages/services/prototyping";
import Manufacturing from "./pages/services/manufacturing";
import Agency from "./pages/agency";
import Sectors from "./pages/sectors";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/sectors" element={<Sectors />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/design" element={<Design />} />
          <Route path="/services/engineering" element={<Engineering />} />
          <Route path="/services/prototyping" element={<Prototyping />} />
          <Route path="/services/manufacturing" element={<Manufacturing />} />
          <Route path="/agency" element={<Agency />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
