import React from "react";

import {
  FoundersSectionContainer,
  FoundersSectionWrapper,
  FoundersCardsWrapper,
  FounderCardContainer,
  FounderCardWrapper,
  FounderCardName,
  FounderCardDescription,
  FounderCardImgWrap,
  FounderCardImg,
  FoundersTopLine,
  FoundersTopLineWrapper,
} from "./FoundersElements";

const Founders = ({ topLine, founders: { founderOne, founderTwo } }) => {
  return (
    <FoundersSectionContainer>
      <FoundersSectionWrapper>
        <FoundersTopLineWrapper>
          <FoundersTopLine>{topLine}</FoundersTopLine>
        </FoundersTopLineWrapper>
        <FoundersCardsWrapper>
          <FounderCard {...founderOne} />
          <FounderCard {...founderTwo} />
        </FoundersCardsWrapper>
      </FoundersSectionWrapper>
    </FoundersSectionContainer>
  );
};

const FounderCard = ({ name, description, img }) => {
  return (
    <FounderCardContainer>
      <FounderCardWrapper>
        <FounderCardName>{name}</FounderCardName>
        {description.map((p, i) => (
          <FounderCardDescription key={`${name}-p${i}`}>
            {p}
          </FounderCardDescription>
        ))}
        <FounderCardImgWrap>
          <FounderCardImg src={img} alt="" />
        </FounderCardImgWrap>
      </FounderCardWrapper>
    </FounderCardContainer>
  );
};

export default Founders;
