import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import InfoSection from "../../components/InfoSection";

import Footer from "../../components/Footer";
import InfoHeader from "../../components/InfoHeader";

import HeadlineBanner from "../../components/HeadlineBanner";

import getLocalizedTexts from "../../utils/getLocalizedTexts";
import { Helmet } from "react-helmet";

const Design = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const img = () => {
    return require("../../images/Yuccalab-logo.svg").default;
  };

  const texts = getLocalizedTexts();
  const menuText = texts.menu;
  const designText = texts.services.design;
  const DesignHeaderObj = designText.header;
  const DesignSummaryObj = designText.summary;
  const DesignBannerObj = designText.banner;
  const DesignSectionObjOne = designText.sectionOne;
  const DesignSectionObjTwo = designText.sectionTwo;
  const DesignSectionObjThree = designText.sectionThree;
  const FooterObj = texts.footer;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.yuccalab.fr/services/design" />
      </Helmet>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <Navbar
        toggle={toggle}
        img={img}
        smoothOpacity={false}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <InfoHeader {...DesignHeaderObj} />
      <InfoSection {...DesignSummaryObj} />
      <HeadlineBanner {...DesignBannerObj} />
      <InfoSection {...DesignSectionObjOne} />
      <InfoSection {...DesignSectionObjTwo} />
      <InfoSection {...DesignSectionObjThree} />
      <Footer {...FooterObj} />
    </>
  );
};

export default Design;
