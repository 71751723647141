import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import { COLORS } from "../constants";

import { Button, ThemeProvider } from "@aws-amplify/ui-react";

export const ButtonScroll = styled(LinkS)`
  border-radius: 50px;
  background: ${({ primary }) =>
    primary ? COLORS.deepblue : COLORS.lightgrey};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? COLORS.dark : COLORS.white)};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary ? COLORS.turquoise : COLORS.turquoise};
    color: ${({ primary }) => (primary ? COLORS.lightgrey : COLORS.lightgrey)};
  }
`;

export const ButtonRedirect = styled(HashLink)`
  border-radius: 50px;
  background: ${({ primary }) =>
    primary ? COLORS.deepblue : COLORS.lightgrey};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? COLORS.dark : COLORS.white)};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary ? COLORS.turquoise : COLORS.cornflowerblue};
  }
`;

const theme = {
  name: "button-form-theme",
  tokens: {
    colors: {
      border: {
        // this will affect the default button's border color
        primary: { value: "transparent" },
      },
    },
    components: {
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: "{fontWeights.extrabold}" },
        borderRadius: { value: "50px" },
        border: { value: "transparent" },
        // style the primary variation
        primary: {
          backgroundColor: { value: COLORS.deepblue },
          _hover: {
            backgroundColor: { value: COLORS.turquoise },
            border: { value: "transparent" },
          },
          _focus: {
            backgroundColor: { value: COLORS.turquoise },
            border: { value: "transparent" },
          },
          _active: {
            backgroundColor: { value: COLORS.turquoise },
            border: { value: "transparent" },
          },
        },
      },
    },
  },
};

export const ButtonForm = ({ buttonLabel }) => (
  <ThemeProvider theme={theme} colorMode="light">
    <Button variation="primary" type="submit">
      {buttonLabel}
    </Button>
  </ThemeProvider>
);

export const ButtonShowEmail = ({ buttonLabel, onClick, id }) => (
  <ButtonShow onClick={onClick} id={id}>
    {buttonLabel}
  </ButtonShow>
);

export const ButtonShow = styled(Button)`
  border-radius: 50px;
  background: ${COLORS.deepblue};
  white-space: nowrap;
  padding: 10px 22px;
  color: ${COLORS.white};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${COLORS.turquoise};
  }
`;
