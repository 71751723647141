import styled from "styled-components";
import { COLORS } from "../../constants";

export const PictureGridContainer = styled.div`
  color: ${COLORS.white};
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  padding-top: 40px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const PictureGridWrapper = styled.div`
  display: grid;
  z-index: 1;
  min-height: 0;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;

export const PictureGridRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1 col1" "col2 col2";
    display: flex;
    flex-direction: column;
  }
`;

export const CellImgWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 20px 40px 20px;
  position: relative;
  color: transparent;

  &:hover {
    transform: scale(1.02);
    color: ${COLORS.white};
    transition: all 0.4s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    color: ${COLORS.white};
  }
`;

export const CellBg = styled.div`
  max-width: 555px;
  width: 100%;
  overflow: hidden;
  /* &:hover {
    filter: blur(4px);
  } */
`;

export const CellImg = styled.img`
  width: 100%;
  margin: 0;
  padding-right: 0;
  -o-object-fit: cover;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const CellTitle = styled.h2`
  /* z-index: 3; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  width: 80%;
`;
