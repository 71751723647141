import styled from "styled-components";
import { COLORS } from "../../constants";

export const ServicesFooterContainer = styled.div`
  background: ${COLORS.cornflowerblue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 340px;
  position: relative;
  z-index: 1;
`;

export const ServicesFooterContent = styled.div`
  z-index: 3;
  max-width: 900px;
  /* position: absolute; */
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.lightgrey};
`;

export const ServicesFooterTitle = styled.h2`
  font-size: 48px;
  text-align: center;
  margin-top: 10px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ServicesFooterSubtitle = styled.p`
  margin-top: 24px;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const ServicesFooterBtnWrapper = styled.div`
  margin: 32px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
