export const enTexts = {
  menu: {
    home: { name: "Home", to: "/#" },
    sectors: { name: "Sectors", to: "/sectors/#" },
    services: {
      name: "Services",
      to: "/services/#",
      dropdown: {
        design: "Design",
        engineering: "Engineering",
        prototyping: "Prototyping",
        manufacturing: "Manufacturing",
      },
    },
    agency: { name: "Our agency", to: "/agency/#" },
    contact: { name: "Contact us", to: "/contact/#" },
  },
  home: {
    heroSection: {
      headLine: "We are the new hardware agency.",
      subtitle: `We bring your product to life from industrial design to manufacturing,
        to contribute to solving some of the world's most pressing problems.`,
    },
    heroFooterSection: {
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      headLine: "We are ",
      subtitle: `Bring your ideas to reality with the first one-stop agency for product
        design and engineering: let's build together, every step of the way.`,
      buttonLabel: "Our services",
      img: require("../images/FaviconLight.svg").default,
      alt: "Yucca lab favicon",
      dark: false,
      primary: true,
      darkText: true,
      redirect: true,
      to: "/services/#",
    },
    agencySection: {
      id: "about",
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      topLine: "Product design agency",
      headLine: "Complexity, made simple.",
      description: `We enable meaningful hardware products, by combining creativity, innovation, 
        engineering experience and a qualified network of industrial partners.`,
      description2: `IoT, Medtech, Manufacturing, Audio, Health, Robotics... 
      Our expertise is adapted to your business sector.`,
      buttonLabel: "Our business sectors",
      imgStart: true,
      img: require("../images/LaserBackgroundVignette.png"),
      alt: "laser lights",
      dark: true,
      primary: false,
      darkText: false,
      redirect: true,
      to: "/sectors/#",
    },
    servicesSection: {
      textColumn: {
        id: "services",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "Services",
        headLine: "Your end-to-end product partner",
        description: `We provide a clear segmentation of your steps towards mass manufacturing.`,
        dark: false,
        darkText: true,
        buttonLabel: "Our services",
        redirect: true,
        to: "/services/#",
        primary: true,
      },
      servicesColumn: {
        serviceOne: {
          title: "Industrial Design",
          description: `We combine product strategy insights and senior industrial design expertise 
            to bring your ideas to life along the most time-efficient path.`,
          imgStart: true,
          img: require("../images/IndustrialDesignNarrow.png"),
          alt: "industrial design",
          to: "/services/design/#",
        },
        serviceTwo: {
          title: "Engineering",
          description: `We are a team of experienced engineers willing to convert 
            your proof of concept into a mass manufacturable design.`,
          imgStart: false,
          img: require("../images/EngineeringNarrow.png"),
          alt: "engineering",
          to: "/services/engineering/#",
        },
        serviceThree: {
          title: "Industrialization",
          description: `We’ve been there before. We rely on a trusted and international 
            network of partners to bring your product to mass manufacturing.`,
          imgStart: true,
          img: require("../images/IndustrializationNarrow.png"),
          alt: "industrialization",
          to: "/services/manufacturing/#",
        },
      },
    },
    impactSection: {
      id: "impact",
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      topLine: "Values",
      headLine: "Shaping the future of engineering",
      description: `At Yucca lab, we believe things must be done differently to address 
        the climate crisis: engineering makes no exception.`,
      description2: `We are a creative team, thinking outside the box to create 
        eco-designed products with the lowest possible environmental impact: 
        we bring to life ideas that contribute to solving some of the world's most pressing problems.`,
      imgStart: false,
      img: require("../images/forest.png"),
      alt: "forest",
      dark: true,
      darkText: false,
      buttonLabel: "Our values",
      primary: false,
      redirect: true,
      to: "/agency/#",
    },
    aboutSection: {
      id: "discover",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      topLine: "About us",
      headLine: "Re-thinking product design",
      description:
        "Get rid of the designers vs. engineers hassle. We embrace both specialties, and deliver delightful working products along the way.",
      buttonLabel: "Get started",
      imgStart: true,
      img: require("../images/ColorfulWaves.png"),
      alt: "designer view",
      dark: false,
      primary: true,
      darkText: true,
      redirect: true,
      to: "/services/design/#",
    },
    referralsSection: {
      title: {
        id: "partners",
        lightBg: false,
        lightText: true,
        topLine: "Partners",
        headLine: "Our clients",
      },
      logos: {
        partnerOne: {
          src: require("../images/HinlabLogo.svg").default,
          alt: "Hinlab",
        },
        partnerTwo: {
          src: require("../images/DreemLogo.svg").default,
          alt: "Dreem",
        },
        partnerThree: {
          src: require("../images/TenakaLogo.svg").default,
          alt: "Tēnaka",
        },
        partnerFour: {
          src: require("../images/VantageLogo.png"),
          alt: "Vantage Robotics",
        },
        partnerFive: {
          src: require("../images/ManeLogo.png"),
          alt: "Mane Biotech",
        },
      },
    },
    contactSection: {
      id: "contact-view",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      topLine: "Contact",
      headLine: "Let's connect!",
      description: `Do you have a project with high impact?`,
      description2: `Our team of designers and engineers is ready to provide industry-leading expertise. 
        We help you build, to reach unprecedented results.`,
      buttonLabel: "Contact us",
      imgStart: true,
      img: require("../images/Sent Message-pana.svg").default,
      alt: "contact us",
      dark: false,
      primary: true,
      darkText: true,
      redirect: true,
      to: "/contact/#",
    },
  },
  sectors: {
    header: {
      id: "sectors-section",
      title: "Our business sectors",
      subtitle:
        "We have gathered field experience for many technical domains, from robotics to wearable devices.",
      img: require("../images/sectors-bg.png"),
    },
    highlights: {
      primary: true,
      dark: false,
      tagline: "Want to know more?",
      btnText: "Contact us",
      highlightObjOne: {
        icon: require("../images/automated-systems-icon.svg").default,
        title: "Automated systems",
      },
      highlightObjTwo: {
        icon: require("../images/medical-device-icon.svg").default,
        title: "Medical devices",
      },
      highlightObjThree: {
        icon: require("../images/wearable-icon.svg").default,
        title: "Wearables",
      },
      highlightObjFour: {
        icon: require("../images/biosensor-icon.svg").default,
        title: "Biosensors",
      },
      highlightObjFive: {
        icon: require("../images/robot-icon.svg").default,
        title: "Robotics",
      },
    },
    features: {
      featureObjOne: {
        title: "Design, innovate",
        buttonLabel: "Discover",
        to: "/services/design/#",
        items: {
          itemOne: {
            img: require("../images/feature-icon-1.svg").default,
            heading: "Specifications",
            subtitle: "Define your scope of work with our team.",
          },
          itemTwo: {
            img: require("../images/feature-icon-2.svg").default,
            heading: "Feasibility",
            subtitle:
              "Study the technical debt of your project: technical complexity, economic reliability, development roadmap.",
          },
          itemThree: {
            img: require("../images/feature-icon-3.svg").default,
            heading: "Design",
            subtitle:
              "Let's sketch your project together! We build your technical requirements documentation and define your design and fabrication strategy.",
          },
        },
      },
      featureObjTwo: {
        title: "Develop, prototype",
        buttonLabel: "Discover",
        to: "/services/prototyping/#",
        items: {
          itemOne: {
            img: require("../images/feature-icon-4.svg").default,
            heading: "Mechanical design",
            subtitle: "Speed up your R&D with our team of engineers.",
          },
          itemTwo: {
            img: require("../images/feature-icon-5.svg").default,
            heading: "Prototyping",
            subtitle:
              "Build, test, iterate. De-risk your innovations and challenge your concepts with our iterative process.",
          },
          itemThree: {
            img: require("../images/feature-icon-6.svg").default,
            heading: "Testing",
            subtitle: "Validate your product features, step by step.",
          },
        },
      },
      featureObjThree: {
        title: "Industrialize",
        buttonLabel: "Discover",
        to: "/services/manufacturing/#",
        items: {
          itemOne: {
            img: require("../images/feature-icon-7.svg").default,
            heading: "Manufacturing",
            subtitle:
              "Secure your mass production by trusting our network of qualified industrials.",
          },
          itemTwo: {
            img: require("../images/feature-icon-8.svg").default,
            heading: "Management",
            subtitle:
              "Strategy, qualification and industrial partners management: our expertise secures the success of your products.",
          },
          itemThree: {
            img: require("../images/feature-icon-9.svg").default,
            heading: "Deliver",
            subtitle:
              "Pre-series, supply, certification: whatever the forecast, on-time delivery is our priority.",
          },
        },
      },
    },
    footerSection: {
      title: "Strengthen your product ideas with our tailored programs",
      subtitle: "Tell us more about your project with a free 30-min call.",
      buttonLabel: "Book a call",
      to: "//calendly.com/martin-herrera-y/30min",
    },
  },
  services: {
    header: {
      id: "services-section",
      title: "Our experts work with you",
      subtitle:
        "Discover our work process, tailored to your hardware needs from design to mass manufacturing.",
      img: require("../images/services-bg.png"),
    },
    headlineSection: {
      id: "services-section",
      titleOne: "Our building process: ",
      titleMark: "comprehensive steps",
      titleTwo: " for complex hardware products",
      subtitleOne:
        "Hardware manufacturing used to be a tedious and risky venture; we say not any more.",
      subtitleTwo: `We have split the process into key steps, reducing the risk and
      accelerating your time to market. We never compromise on testing and
      validation, to build rock-solid sustainable solutions that just work.`,
      steps: {
        design: {
          name: "design",
          img: require("../images/Starting a business project-bro.svg").default,
          to: "/services/design/#",
        },
        engineering: {
          name: "engineering",
          img: require("../images/Prototyping process-amico.svg").default,
          to: "/services/engineering/#",
        },
        prototyping: {
          name: "prototyping",
          img: require("../images/Starting a business project-amico-green.svg")
            .default,
          to: "/services/prototyping/#",
        },
        manufacturing: {
          name: "industrialization",
          img: require("../images/Manufacturing Process-rafiki.svg").default,
          to: "/services/manufacturing/#",
        },
      },
    },
    highlightsSection: {
      highlightObjOne: {
        icon: require("../images/fi-rr-diploma.svg").default,
        title: "Our ideas become your IP",
        subtitle: "You own the ingenious ideas we bring to life for you.",
      },
      highlightObjTwo: {
        icon: require("../images/fi-rr-graduation-cap.svg").default,
        title: "Train your teams with us",
        subtitle:
          "In the long run, you won’t need costly agencies. We train your teams along the way, so you can keep knowledge internally.",
      },
      highlightObjThree: {
        icon: require("../images/fi-rr-sort-amount-up-alt.svg").default,
        title: "Agile hardware building",
        subtitle:
          "Prototype, test, repeat is our motto; we don’t stop until we reach perfection.",
      },
      highlightObjFour: {
        icon: require("../images/fi-rr-users-alt.svg").default,
        title: "We stick with you, until the very end",
        subtitle:
          "The same people for the same project is the only way to success.",
      },
      highlightObjFive: {
        icon: require("../images/mind-share.svg").default,
        title: "We get our hands dirty",
        subtitle:
          "The hardest challenges bring the best ideas out of us. Come with a really tough one, and we’ll be the perfect match.",
      },
    },
    footerSection: {
      title: "Strengthen your product ideas with our tailored programs",
      subtitle: "Tell us more about your project with a free 30-min call.",
      buttonLabel: "Book a call",
      to: "//calendly.com/martin-herrera-y/30min",
    },
    design: {
      header: {
        id: "design-section",
        title: "Skyrocketting your innovation",
        subtitle: "We design your most ambitious projects.",
        img: require("../images/design-bg.png"),
      },
      summary: {
        id: "industrial-design",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "Design & Ideation",
        headLine: "Industrial Design",
        description: `We strive to create smart, unique, and intuitive solutions that have a positive 
        and lasting impact on people and the world around them. As a result, our designs fit better 
        into people’s lives in ways that are useful, beautiful and meaningful.`,
        description2: `Our thoughtful process ensures products are beautifully and deliberately designed, 
        engineered, manufactured, packaged, and branded.`,
        imgStart: false,
        img: require("../images/Starting a business project-bro-blue.svg")
          .default,
        alt: "product design",
        darkText: true,
        tight: true,
        items: [
          "• Comparative analysis",
          "• Consumer interviews",
          "• Lifestyle persona analysis",
          "• Color, material and finish",
          "• Design thinking",
          "• Industrial design",
          "• UI & UX design",
          "• Packaging design",
          "• Product development",
          "• Concept visualization",
          "• 3D rendering",
          "• Physical model making",
          "• Material & detail specification",
          "• Sustainable design",
        ],
      },
      banner: {
        id: "design-banner",
        title: "Let's build the ideas of tomorrow, together",
        img: require("../images/design-banner.png"),
      },
      sectionOne: {
        id: "product-specifications",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "1.",
        headLine: "Product specifications",
        description: `Prior to writing your spec sheets, Yucca lab brings you up to speed with some key analysis:`,
        imgStart: false,
        img: require("../images/design-vignette-1.png"),
        alt: "product specifications",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
        items: [
          "• State-of the art products and applicable norms",
          "• Competition and existing patents",
          "• Ergonomics study",
          "• Customer discovery (consumer profile, needs, etc)",
        ],
      },
      sectionTwo: {
        id: "creative-process",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "2.",
        headLine: "Creative process",
        description: `Our team of designers works hands in hands with our mechanical engineers, 
        to create innovative and feasible concepts. Manufacturability is at the core of our 
        ideation process; so is the user experience.`,
        description2: `Our fast prototyping experience enables us to build mockups, test 
        and iterate in order to meet specifications and create an ergonomic design, 
        tailored to its users' needs.`,
        imgStart: true,
        img: require("../images/design-vignette-2.png"),
        alt: "product specifications",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionThree: {
        id: "from-sketch-to-prototype",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "3.",
        headLine: "From sketch to prototype",
        description: `We make promising concepts come to life, with the most advanced 
        3D modelling tools and cosmetic prototyping techniques.`,
        description2: `This key step elevates your product and enables to validate its shapes, 
        materials, colors and textures, before committing to the engineering phase.`,
        imgStart: false,
        img: require("../images/design-vignette-3.png"),
        alt: "product specifications",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
    },
    engineering: {
      header: {
        id: "engineering-section",
        title: "Our mechanical experts serve your most ambitious projects",
        subtitle: "We design, build and manufacture your hardware products.",
        img: require("../images/engineering-bg.png"),
      },
      summary: {
        id: "mechanical-engineering",
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: "Engineering",
        headLine: "Mechanical Engineering",
        description: `Yucca lab specializes in developing smart, elegant, and robust mechanical concepts to solve complex engineering problems.`,
        description2: `Our collaborative approach to innovation ensures a smooth transition from design to production. We create the product architecture that supports innovative forms, implementing novel mechanisms and efficiencies wherever possible.`,
        imgStart: false,
        img: require("../images/Prototyping process-amico.svg").default,
        alt: "mechanical engineering",
        darkText: false,
        tight: false,
        items: [
          "• UX engineering & optimization",
          "• 3D design CAD",
          "• IP creation",
          "• Technology integration",
          "• Cost optimization",
          "• Manufacturing & tooling strategy",
          "• Mechanism design",
          "• Tight component packaging",
          "• Mechanical product architecture",
          "• Structural design ",
          "• Design for Manufacturing (DFM)",
        ],
      },
      banner: {
        id: "engineering-banner",
        title: "Mechanical and industrial design for your product",
        img: require("../images/engineering-banner.png"),
      },
      sectionOne: {
        id: "technical-audit",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "1.",
        headLine: "Technical audit",
        description: `We evaluate your product maturity and identify the most adapted design 
        and industrialization strategies.`,
        description2: `In just a few days, you will receive a detailed list of recommendations, 
        before an industrialization cycle can be kick-started. During this phase, we also 
        provide an estimation of your industrial roadmap.`,
        imgStart: false,
        img: require("../images/engineering-vignette-1.png"),
        alt: "technical audit",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionTwo: {
        id: "mechanical-design",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "2.",
        headLine: "Mechanical design",
        description: `Our experienced mechanical engineers deal with your most ambitious 
        designs. We work side by side with electronics design offices, and deliver 
        parametric and robust models.`,
        imgStart: true,
        img: require("../images/engineering-vignette-2.png"),
        alt: "mechanical design",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionThree: {
        id: "innovation",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "3.",
        headLine: "A source for innovation",
        description: `Create breakthrough innovations with us. We design patentable innovations, 
        that bring your projects to the next level.`,
        description2: `Creating tailored unique solutions for every challenge is our motto. 
        The innovations we provide give you a competitive advantage, even on the long run.`,
        imgStart: false,
        img: require("../images/engineering-vignette-3.png"),
        alt: "innovation",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
    },
    prototyping: {
      header: {
        id: "prototyping-section",
        title: "Prototype, test, iterate",
        subtitle: "Our iterative process for your hardware.",
        img: require("../images/prototyping-bg.png"),
      },
      summary: {
        id: "prototyping",
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: "Engineering",
        headLine: "Prototyping",
        description: `We believe in hands-on iterations: perfection can only be achieved with extensive prototyping and testing process every step of the way. Everything we create is designed & engineered by doing.`,
        description2: `We make the most of this back-and-forth conversation between design and prototypes, creating an iterative process in which each tool reveals new opportunities and problems to explore and further refine.`,
        imgStart: false,
        img: require("../images/Starting a business project-amico.svg").default,
        alt: "prototype",
        darkText: false,
        tight: true,
        items: [
          "• 3D printing",
          "• Rapid prototyping",
          "• CNC machining",
          "• Laser cutting",
          "• Smart textiles integration",
          "• Wearable assembly",
          "• Assembly & refinement",
          "• Prototype vendor management",
          "• Cosmetic model",
        ],
      },
      bannerOne: {
        id: "prototyping-banner",
        title: "Validate your product features",
        subtitle:
          "Our agile process enables a quick testing of industrial concepts: it only takes a few days for a full iterative cycle.",
        img: require("../images/prototyping-banner-1.png"),
      },
      listIconSection: {
        id: "prototyping-list",
        lightBg: true,
        listIconSectionOne: {
          id: "prototyping-list-1",
          img: require("../images/prototyping-icon-1.svg").default,
          topline: "1.",
          heading: "Demonstrator",
          subtitle: `As the first essential basis for feedback collection, the demonstrator enables 
            user testing and sharply embodies our ideas.`,
          subtitle2: `This POC (Proof Of Concept) or MVP (Minimum Viable Product) validates the key technical features of your solution.`,
        },
        listIconSectionTwo: {
          id: "prototyping-list-2",
          img: require("../images/prototyping-icon-2.svg").default,
          topline: "2.",
          heading: "Cosmetic model",
          subtitle: `The cosmetic model terminates any reliable design phase. We use 
          advanced cosmetic modelling techniques to create a high fidelity mockup; it is
          essential to validate shapes, colors, materials and finish.`,
        },
        listIconSectionThree: {
          id: "prototyping-list-3",
          img: require("../images/prototyping-icon-3.svg").default,
          topline: "3.",
          heading: "Functional prototype",
          subtitle: `Industrial design and technical features meet within the functional 
          prototype. It embodies most of the product features, while speaking a designer's 
          language.`,
          subtitle2: `At Yucca lab, we question the industrial feasibility at the earliest: 
          the functional prototype is also a good opportunity to address the integration and 
          assembly challenges.`,
        },
      },
      bannerTwo: {
        id: "prototyping-banner",
        title: "Technologies",
        img: require("../images/prototyping-banner-2.png"),
      },
      callout: {
        id: "prototyping-callout",
        lightBg: true,
        img: require("../images/prototyping-icon-4.svg").default,
        text: `We work with the most advanced technologies to build our prototypes. We use a 
        broad spectrum technologies, including 3D printing (SLA, SLS, MJF, polyjet...), 
        rapid CNC machining or vacuum casting, to create high fidelity models and quickly tackle 
        all engineering challenges.`,
      },
      prototypingGrid: {
        id: "prototyping-techniques",
        lightBg: true,
        imgObjOne: {
          id: "3d-print",
          img: require("../images/3d-print.png"),
          alt: "3D printing",
          title: "3D printing",
        },
        imgObjTwo: {
          id: "lasercut",
          img: require("../images/lasercut.png"),
          alt: "Laser cutting",
          title: "Laser cutting",
        },
        imgObjThree: {
          id: "cnc",
          img: require("../images/cnc.png"),
          alt: "CNC machining",
          title: "CNC machining",
        },
        imgObjFour: {
          id: "vacuum-casting",
          img: require("../images/vacuum-casting.png"),
          alt: "Vacuum casting",
          title: "Vacuum casting",
        },
      },
    },
    manufacturing: {
      header: {
        id: "manufacturing-section",
        title: "Activate your mass production",
        subtitle: "We ramp-up your production, until its full capacity.",
        img: require("../images/manufacturing-bg.png"),
      },
      summary: {
        id: "manufacturing",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "Industrialization",
        headLine: "Manufacturing",
        description: `Your products are unique, and they all need to be flawless. We have gathered years of field experience which we leverage on the production line with our industrial partners.`,
        description2: `Manufacturing is a tedious process, and we are relentless: we can’t stop pushing, until the result exceeds our expectations.`,
        imgStart: false,
        img: require("../images/Manufacturing Process-rafiki.svg").default,
        alt: "production line",
        darkText: true,
        tight: false,
        items: [
          "• Request for Quotation (RFQ)",
          "• Prescan testing",
          "• Supplier sourcing",
          "• EMS relationship management",
          "• Production management",
          "• Product optimization",
          "• Quality management & inspection",
          "• Production planning",
          "• Ramp-up & mass production",
        ],
      },
      banner: {
        id: "manufacturing-banner",
        title: "The highest quality grade for your production",
        img: require("../images/manufacturing-banner.png"),
      },
      sectionOne: {
        id: "optimization",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "1.",
        headLine: "Optimization",
        description: `We take you on the DFM (Design For Manufacturing), DFA (Design For Assembly) 
        and DTC (Design To Cost) journey, to reduce manufacturing costs, 
        simplify the assembly and ensure the highest quality finish for all parts.`,
        imgStart: false,
        img: require("../images/manufacturing-vignette-1.png"),
        alt: "optimization",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionTwo: {
        id: "indus-strategy",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "2.",
        headLine: "Industrialization strategy",
        description: `Whatever your production size, we help you choose the best 
        industrialization strategy with respect to your forecast and cost of goods sold (COGS).`,
        imgStart: true,
        img: require("../images/manufacturing-vignette-2.png"),
        alt: "industrialization strategy",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
        items: [
          "• Material selection",
          "• Finish quality",
          "• Production strategy",
          "• Partner selection",
        ],
      },
      sectionThree: {
        id: "supplier-management",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "3.",
        headLine: "Supplier Management",
        description: `We come at the interface with suppliers, we lead the development 
        of fabrication tooling and we install a rigorous quality control process.`,
        description2: `We produce the necessary documentation for all industrialization phases (EVT, DVT, PVT). `,
        imgStart: false,
        img: require("../images/manufacturing-vignette-3.png"),
        alt: "supplier management",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
        items: [
          "• PRD (Product Requirements Document)",
          "• 3D model",
          "• 2D quality drawings",
          "• BOM (Bill Of Materials)",
          "• OBA (Out of the Box Acceptancy)",
          "• CMF (Color Material Finish)",
          "• Assembly documentation",
        ],
      },
      sectionFour: {
        id: "certification",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "4.",
        headLine: "Certification",
        description: `The product certification ensures the stability of fabrication, 
        with respect to performance criteria that are defined in the desired norm.`,
        description2: `With a specialty in medical devices for many years, our team can 
        walk you through the most tedious certifications, such as ISO 13485 or the CE marking.`,
        imgStart: true,
        img: require("../images/manufacturing-vignette-4.png"),
        alt: "certification",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
    },
  },
  contact: {
    heading: "Tell us more about your project",
    subtitle: "Fill the form below, or directly send an email to us.",
    emailButtonText: "See contact email",
    emailMark: "hello@yuccalab.fr",
    form: {
      title: "Contact",
      fillMessage:
        "Fill out this form to connect with us. We'll be in touch soon!",
      confirmationMessage:
        "Your message was sent successfully. We'll be in touch soon!",
      nameLabel: "Your Name",
      namePlaceholder: "Your name",
      emailLabel: "Email",
      emailPlaceholder: "you@email.com",
      messageLabel: "Message",
      messagePlaceholder: "Enter your message",
      buttonLabel: "Send Message",
    },
  },
  agency: {
    headerSection: {
      id: "agency",
      title: "Our agency reshapes product design",
      subtitle:
        "We break the codes of product design, to create the innovations of tomorrow.",
      img: require("../images/agency-bg.png"),
    },
    headlineSection: {
      id: "values-headline",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      imgStart: false,
      img: require("../images/dna-square.png"),
      alt: "dna image",
      darkText: true,
      darkItems: true,
      tight: false,
      tightLayout: false,
      topLine: "Values",
      headLine: "Our values",
      description: `Our mission is to build the most innovative physical products, by reconciling
      industrial design and state-of-the-art engineering.`,
      description2: `After many years of work in the field of product design, we felt we needed to 
      bridge the gap between industrial design and engineering. Yucca lab gathers talents from both 
      worlds, to create a unique approach for product design.`,
      description3: `Our designs are feasible from day one. We do not tolerate designs that are 
      impossible to make, and push simple and elegant concepts, that are compatible with modern 
      technologies and production methods.`,
    },
    valuesSection: {
      id: "values-list",
      lightBg: true,
      textContents: [
        {
          title: {
            before: "",
            mark: "Sustainable design",
            after: " is the root of our ideas",
            markColor: "#8AAFF9",
          },
          description: `At Yucca lab, we work hard to create a more sustainable world; a world that 
          takes climate issues into consideration before creating products. Our designs are 
          sustainable, and we favour local productions, that promote short supply chains.`,
        },
        {
          title: {
            before: "We stay close to ",
            mark: "your teams",
            after: "",
            markColor: "#12C9BE",
          },
          description: `We work hand in hand with your technical teams. We are convinced that to 
          create breakthrough innovations, we need to avoid the opaque way a traditional design 
          office operates. Our consultants integrate thoroughly with your organization, to work 
          in synergy with you.`,
        },
        {
          title: {
            before: "",
            mark: "Simplicity",
            after: ", first and foremost",
            markColor: "#FF9B70",
          },
          description: `Our design process relies on clear foundations that create robust concepts 
          for our creations. We believe that a product with tons of features will lack accuracy 
          in most of them ; at Yucca lab, we focus on the essential to create products that are 
          simple to manufacture, and last many years.`,
        },
        {
          title: {
            before: "",
            mark: "User centric",
            after: "",
            markColor: "#D1B1FF",
          },
          description: `Through our designs, we always put the end user on a pedestal. We focus 
          first on the product usage, because a successful product is one that people want to use, 
          without any friction whatsoever. At the end of the day, we always aim at creating the 
          next flagship product for your brand.`,
        },
        {
          title: {
            before: "Learn by ",
            mark: "doing",
            after: "",
            markColor: "#8AAFF9",
          },
          description: `To build flawless products, one has to start small, tu validate basic 
          concepts before aiming for more ambitious goals. We build prototypes as soon as possible, 
          to identify any issue and get rid of design flaws before they occur on a production line.`,
        },
      ],
    },
    infoSectionOne: {
      id: "values-remote",
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      imgStart: true,
      img: require("../images/yucca-favicon-values.svg").default,
      alt: "yucca lab logo",
      darkText: false,
      darkItems: false,
      tight: false,
      tightLayout: true,
      headLine: "Design from anywhere",
      description: `Our agency is decentralized. We work from anywhere in France, and select 
      talented profiles according to their skills, rather than the place they live.`,
      description2: `We prove everyday that this world is connected, and can operate remotely 
      in an efficient fashion. Our experts travel to anywhere in France and abroad, as soon as 
      an assembly phase is required.`,
      description3: `Working with Yucca lab means fewer business lunches, and more tasks delivered 
      on time.`,
    },
    infoSectionTwo: {
      id: "values-sites",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      imgStart: false,
      img: require("../images/map-outline-stroke.svg").default,
      alt: "yucca lab logo",
      darkText: true,
      darkItems: true,
      tight: true,
      tightLayout: true,
      topLine: "Agency",
      headLine: "We stay close to you",
      description: `We are based in several sites in France, to stay close to our clients.`,

      description2: `Our motto is design, test, iterate. To do so, we need to stay close to your 
      team and your R&D site; our experts come to you, wherever you are based.`,
    },
    foundersSection: {
      topLine: "Our founders",
      founders: {
        founderOne: {
          name: "Camille Kerbaul, CEO",
          description: [
            `With a degree in engineering for health from Arts et Métiers ParisTech, Camille
            started his career in Boston, at the Wyss Institute for Biologically Inspired 
            Engineering (Harvard), where he became passionate about innovative and cross-discipline 
            projects that gather doctors, biologists and engineers. He then discovered deep techs 
            by joining the team at Dreem.`,
            `In 2021, he started Yucca lab, our agency of experts, to bring the most ambitious 
            projects to life.`,
          ],
          img: require("../images/camille-kerbaul.png"),
        },
        founderTwo: {
          name: "Martin Herrera, COO",
          description: [
            `Martin was trained at École polytechnique and ETH Zürich, with majors in mechanics 
            and fundamental physics. He specialized in R&D operations and biomedical sensors at Dreem 
            for the first years of his career.`,
            `He joined Yucca lab in January 2022, to optimize our industrialization processes and
            lead the R&D operations performed for our clients.`,
          ],
          img: require("../images/martin-herrera.png"),
        },
      },
    },
  },
  footer: {
    sections: {
      col1: {
        title: "About us",
        howWeWork: { name: "How we work", to: "/#about" },
        careers: { name: "Careers", to: "/careers/#" },
        privacy: { name: "Privacy Policy", to: "/privacypolicy/#" },
      },
      col2: {
        title: "Contact us",
        contact: { name: "Contact", to: "/contact/#" },
        intro: {
          name: "Intro call",
          to: "//calendly.com/martin-herrera-y/30min",
        },
      },
      col3: {
        title: "Social Media",
        linkedin: {
          name: "LinkedIn",
          to: "//www.linkedin.com/company/73061624/",
        },
        instagram: { name: "Instagram", to: "//www.instagram.com/yuccalab/" },
        facebook: { name: "Facebook", to: "//www.facebook.com/yuccalab" },
      },
    },
    copyrights: { text1: "Yucca lab © ", text2: " All rights reserved." },
  },
};
