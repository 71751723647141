import styled from "styled-components";
import { COLORS } from "../../constants";

export const InfoHeaderContainer = styled.div`
  background: ${COLORS.lightgrey};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 30px 0 30px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 100px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    padding: 120px 0 30px 0;
  }
`;

export const InfoHeaderBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const InfoHeaderImg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const InfoHeaderContent = styled.div`
  z-index: 3;
  min-height: 637px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`;

export const InfoHeaderH1 = styled.h1`
  color: ${COLORS.lightgrey};
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const InfoHeaderH2 = styled.p`
  color: ${COLORS.lightgrey};
  margin-top: 24px;
  font-size: 20px;
  text-align: center;
  max-width: 1000px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
