import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import InfoSection from "../../components/InfoSection";

import Footer from "../../components/Footer";
import InfoHeader from "../../components/InfoHeader";

import HeadlineBanner from "../../components/HeadlineBanner";

import getLocalizedTexts from "../../utils/getLocalizedTexts";
import { Helmet } from "react-helmet";

const Engineering = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const img = () => {
    return require("../../images/Yuccalab-logo.svg").default;
  };

  const texts = getLocalizedTexts();
  const menuText = texts.menu;
  const engineeringText = texts.services.engineering;
  const EngineeringHeaderObj = engineeringText.header;
  const EngineeringSummaryObj = engineeringText.summary;
  const EngineeringBannerObj = engineeringText.banner;
  const EngineeringSectionObjOne = engineeringText.sectionOne;
  const EngineeringSectionObjTwo = engineeringText.sectionTwo;
  const EngineeringSectionObjThree = engineeringText.sectionThree;
  const FooterObj = texts.footer;

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.yuccalab.fr/services/engineering"
        />
      </Helmet>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <Navbar
        toggle={toggle}
        img={img}
        smoothOpacity={false}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <InfoHeader {...EngineeringHeaderObj} />
      <InfoSection {...EngineeringSummaryObj} />
      <HeadlineBanner {...EngineeringBannerObj} />
      <InfoSection {...EngineeringSectionObjOne} />
      <InfoSection {...EngineeringSectionObjTwo} />
      <InfoSection {...EngineeringSectionObjThree} />
      <Footer {...FooterObj} />
    </>
  );
};

export default Engineering;
