import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { COLORS } from "../../constants";

export const ServicesHeaderContainer = styled.div`
  background: ${COLORS.lightgrey};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 30px 0 30px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 100px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    padding: 120px 0 30px 0;
  }
`;

export const ServicesHeaderWrapper = styled.div`
  min-height: 860px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`;

export const ServicesHeaderH2 = styled.h2`
  color: ${COLORS.dark};
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ServicesHeaderH3 = styled.p`
  color: ${COLORS.dark};
  margin-top: 24px;
  font-size: 20px;
  text-align: center;
  max-width: 1000px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const ServicesHeaderImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: stretch;
  margin: 80px 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: 40px 0;
  }
`;

export const ServicesHeaderLink = styled(HashLink)`
  text-decoration: none;
  color: inherit;
`;

export const ServicesHeaderStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesHeaderImg = styled.img`
  width: 100%;

  @media screen and (max-width: 480px) {
    width: 70%;
  }
`;

export const ServicesHeaderStepTitle = styled.h3`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${({ hover, hoverColor }) =>
    !hover ? "inherit" : hoverColor ? hoverColor : COLORS.turquoise};
`;

export const ChevronContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  @media screen and (max-width: 800px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    padding-top: 30px;
  }
`;

export const Chevron = () => {
  // return <ServicesHeaderChevron>{">"}</ServicesHeaderChevron>;
  return <ChevronImg src={require("../../images/Chevron.svg").default} />;
};

export const ChevronImg = styled.img`
  height: 60px;

  @media screen and (max-width: 480px) {
    height: 40px;
    rotate: 90deg;
  }
`;
