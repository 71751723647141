import React, { useState } from "react";
import {
  ServicesHeaderContainer,
  ServicesHeaderWrapper,
  ServicesHeaderH2,
  ServicesHeaderH3,
  ServicesHeaderImgWrapper,
  ServicesHeaderLink,
  ServicesHeaderStepContainer,
  ServicesHeaderImg,
  ServicesHeaderStepTitle,
  Chevron,
  ChevronContainer,
} from "./ServiesHeaderElements";
import { Mark } from "../HeroFooterSection/HeroFooterElements";
import { COLORS } from "../../constants";

const ServicesHeader = ({
  titleOne,
  titleMark,
  titleTwo,
  subtitleOne,
  subtitleTwo,
  steps,
}) => {
  const [hover1, setHover1] = useState(false);
  const onHoverEnter1 = () => {
    setHover1(true);
  };
  const onHoverLeave1 = () => {
    setHover1(false);
  };
  const [hover2, setHover2] = useState(false);
  const onHoverEnter2 = () => {
    setHover2(true);
  };
  const onHoverLeave2 = () => {
    setHover2(false);
  };
  const [hover3, setHover3] = useState(false);
  const onHoverEnter3 = () => {
    setHover3(true);
  };
  const onHoverLeave3 = () => {
    setHover3(false);
  };
  const [hover4, setHover4] = useState(false);
  const onHoverEnter4 = () => {
    setHover4(true);
  };
  const onHoverLeave4 = () => {
    setHover4(false);
  };
  return (
    <ServicesHeaderContainer>
      <ServicesHeaderWrapper>
        <ServicesHeaderH2>
          {titleOne}
          <Mark>{titleMark}</Mark>
          {titleTwo}
        </ServicesHeaderH2>
        <ServicesHeaderH3>{subtitleOne}</ServicesHeaderH3>
        <ServicesHeaderH3>{subtitleTwo}</ServicesHeaderH3>
        <ServicesHeaderImgWrapper>
          <ServicesHeaderLink to={steps.design.to}>
            <ServicesHeaderStepContainer
              onMouseEnter={onHoverEnter1}
              onMouseLeave={onHoverLeave1}
            >
              <ServicesHeaderImg src={steps.design.img} />
              <ServicesHeaderStepTitle hover={hover1} hoverColor={COLORS.lilac}>
                {steps.design.name}
              </ServicesHeaderStepTitle>
            </ServicesHeaderStepContainer>
          </ServicesHeaderLink>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
          <ServicesHeaderLink to={steps.engineering.to}>
            <ServicesHeaderStepContainer
              onMouseEnter={onHoverEnter2}
              onMouseLeave={onHoverLeave2}
            >
              <ServicesHeaderImg src={steps.engineering.img} />
              <ServicesHeaderStepTitle hover={hover2} hoverColor={COLORS.coral}>
                {steps.engineering.name}
              </ServicesHeaderStepTitle>
            </ServicesHeaderStepContainer>
          </ServicesHeaderLink>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
          <ServicesHeaderLink to={steps.prototyping.to}>
            <ServicesHeaderStepContainer
              onMouseEnter={onHoverEnter3}
              onMouseLeave={onHoverLeave3}
            >
              <ServicesHeaderImg src={steps.prototyping.img} />
              <ServicesHeaderStepTitle
                hover={hover3}
                hoverColor={COLORS.turquoise}
              >
                {steps.prototyping.name}
              </ServicesHeaderStepTitle>
            </ServicesHeaderStepContainer>
          </ServicesHeaderLink>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
          <ServicesHeaderLink to={steps.manufacturing.to}>
            <ServicesHeaderStepContainer
              onMouseEnter={onHoverEnter4}
              onMouseLeave={onHoverLeave4}
            >
              <ServicesHeaderImg src={steps.manufacturing.img} />
              <ServicesHeaderStepTitle
                hover={hover4}
                hoverColor={COLORS.cornflowerblue}
              >
                {steps.manufacturing.name}
              </ServicesHeaderStepTitle>
            </ServicesHeaderStepContainer>
          </ServicesHeaderLink>
        </ServicesHeaderImgWrapper>
      </ServicesHeaderWrapper>
    </ServicesHeaderContainer>
  );
};

export default ServicesHeader;
