export const frTexts = {
  menu: {
    home: { name: "Accueil", to: "/#" },
    sectors: { name: "Secteurs d'activité", to: "/sectors/#" },
    services: {
      name: "Notre expertise",
      to: "/services/#",
      dropdown: {
        design: "Design",
        engineering: "Conception",
        prototyping: "Prototypage",
        manufacturing: "Industrialisation",
      },
    },
    agency: { name: "L'agence", to: "/agency/#" },
    contact: { name: "Contactez-nous", to: "/contact/#" },
  },
  home: {
    heroSection: {
      headLine: "Conception produit, de A à Z.",
      subtitle: `Yucca lab est l’agence de conception nouvelle génération, 
      du design industriel à la fabrication en série.`,
    },
    heroFooterSection: {
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      headLine: "Nous sommes ",
      subtitle: `Concrétisez vos idées avec nos équipes de designers industriels et nos 
      ingénieurs mécanique : nous construisons ensemble vos projets industriels.`,
      buttonLabel: "Nos services",
      img: require("../images/FaviconLight.svg").default,
      alt: "Yucca lab favicon",
      dark: false,
      primary: true,
      darkText: true,
      redirect: true,
      to: "/services/#",
    },
    agencySection: {
      id: "about",
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      topLine: "Agence de conception produit",
      headLine: "Simplifiez votre conception",
      description: `Nous créons des produits hardware innovants en combinant créativité, 
      expérience en ingénierie et un réseau d’industriels qualifiés.`,
      description2: `IoT, Medtech, Fabrication, Audio, Santé, Robotique... 
      Nous avons l’expertise métiers adaptée à votre secteur d’activité.`,
      buttonLabel: "Nos secteurs d'activité",
      imgStart: true,
      img: require("../images/LaserBackgroundVignette.png"),
      alt: "laser lights",
      dark: true,
      primary: false,
      darkText: false,
      redirect: true,
      to: "/sectors/#",
    },
    servicesSection: {
      textColumn: {
        id: "services",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "Services",
        headLine: "Du design à l'industrialisation",
        description: `Nos équipes d'experts vous accompagnent de A à Z.`,
        dark: false,
        darkText: true,
        buttonLabel: "Notre expertise",
        redirect: true,
        to: "/services/#",
        primary: true,
      },
      servicesColumn: {
        serviceOne: {
          title: "Design industriel",
          description: `Nous proposons des designs innovants, épurés, et réalistes.
          Notre équipe de designers industriels saura comprendre et répondre aux cahiers des charges les plus complexes.`,
          imgStart: true,
          img: require("../images/IndustrialDesignNarrow.png"),
          alt: "design industriel",
          to: "/services/design/#",
        },
        serviceTwo: {
          title: "Ingénierie",
          description: `Nous sommes une équipe d'ingénieurs expérimentés prêts à convertir votre preuve de concept en un modèle industrialisable.
          Nous atteignons la perfection pour votre produit grâce à un processus de prototypage itératif et des tests intensifs. `,
          imgStart: false,
          img: require("../images/EngineeringNarrow.png"),
          alt: "ingénierie",
          to: "/services/engineering/#",
        },
        serviceThree: {
          title: "Industrialisation",
          description: `Nous nous appuyons sur un réseau d’industriels de confiance pour emmener vos produits jusqu’à leur fabrication en série.`,
          imgStart: true,
          img: require("../images/IndustrializationNarrow.png"),
          alt: "industrialisation",
          to: "/services/manufacturing/#",
        },
      },
    },
    impactSection: {
      id: "impact",
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      topLine: "Valeurs",
      headLine: "Dessinons le futur ensemble",
      description: `Chez Yucca lab, nous pensons que les choses doivent être faites différemment pour faire face à la crise climatique, sans exception pour le hardware.`,
      description2: `Nous sommes une équipe de personnes créatives. Nous sortons des sentiers battus pour créer des produits éco-conçus ayant le plus faible impact possible sur l'environnement : nous donnons vie à des idées qui contribuent à rendre le monde meilleur.`,
      imgStart: false,
      img: require("../images/forest.png"),
      alt: "forest",
      dark: true,
      darkText: false,
      buttonLabel: "Nos valeurs",
      primary: false,
      redirect: true,
      to: "/agency/#",
    },
    aboutSection: {
      id: "discover",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      topLine: "À propos",
      headLine: "Le design, autrement",
      description: `Notre équipe rassemble ingénieurs talentueux et designers expérimentés. Nos idées sont rapidement réalisables, car elles tiennent compte des contraintes de conception dès les premières esquisses.`,
      buttonLabel: "Design industriel",
      imgStart: true,
      img: require("../images/ColorfulWaves.png"),
      alt: "designer view",
      dark: false,
      primary: true,
      darkText: true,
      redirect: true,
      to: "/services/design/#",
    },
    referralsSection: {
      title: {
        id: "partners",
        lightBg: false,
        lightText: true,
        topLine: "Partners",
        headLine: "Ils nous font confiance",
      },
      logos: {
        partnerOne: {
          src: require("../images/HinlabLogo.svg").default,
          alt: "Hinlab",
        },
        partnerTwo: {
          src: require("../images/DreemLogo.svg").default,
          alt: "Dreem",
        },
        partnerThree: {
          src: require("../images/TenakaLogo.svg").default,
          alt: "Tēnaka",
        },
        partnerFour: {
          src: require("../images/VantageLogo.png"),
          alt: "Vantage Robotics",
        },
        partnerFive: {
          src: require("../images/ManeLogo.png"),
          alt: "Mane Biotech",
        },
      },
    },
    contactSection: {
      id: "contact-view",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      topLine: "Contact",
      headLine: "Prenons rendez-vous",
      description: `Vous souhaitez accélérer votre mise sur le marché ?`,
      description2: `Notre équipe de concepteurs et d'ingénieurs est prête à vous fournir une expertise de pointe. Nous vous aidons à construire, pour atteindre des résultats sans précédent.`,
      buttonLabel: "Contact us",
      imgStart: true,
      img: require("../images/Sent Message-pana.svg").default,
      alt: "contact us",
      dark: false,
      primary: true,
      darkText: true,
      redirect: true,
      to: "/contact/#",
    },
  },
  sectors: {
    header: {
      id: "sectors-section",
      title: "Nos secteurs d’activité",
      subtitle:
        "Nos expertises métiers vous accompagnent dans de nombreux domaines techniques.",
      img: require("../images/sectors-bg.png"),
    },
    highlights: {
      primary: true,
      dark: false,
      tagline: "Vous souhaitez en apprendre plus ?",
      btnText: "Contactez-nous",
      highlightObjOne: {
        icon: require("../images/automated-systems-icon.svg").default,
        title: "Systèmes automatisés",
      },
      highlightObjTwo: {
        icon: require("../images/medical-device-icon.svg").default,
        title: "Dispositifs médicaux",
      },
      highlightObjThree: {
        icon: require("../images/wearable-icon.svg").default,
        title: "Wearables",
      },
      highlightObjFour: {
        icon: require("../images/biosensor-icon.svg").default,
        title: "Biosensors",
      },
      highlightObjFive: {
        icon: require("../images/robot-icon.svg").default,
        title: "Robotique",
      },
    },
    features: {
      featureObjOne: {
        title: "Designer, innover",
        buttonLabel: "Découvrir",
        to: "/services/design/#",
        items: {
          itemOne: {
            img: require("../images/feature-icon-1.svg").default,
            heading: "Spécifications",
            subtitle:
              "Définissez et étudiez le cahier des charges de votre produit avec nos équipe.",
          },
          itemTwo: {
            img: require("../images/feature-icon-2.svg").default,
            heading: "Faisabilité",
            subtitle:
              "Évaluez la viabilité technique de votre projet : complexité technique, viabilité économique, roadmap de développement.",
          },
          itemThree: {
            img: require("../images/feature-icon-3.svg").default,
            heading: "Design",
            subtitle:
              "Esquissons ensemble les contours de votre projet ! Nous construisons votre cahier des charges et définissons la stratégie conception et de fabrication.",
          },
        },
      },
      featureObjTwo: {
        title: "Développer, prototyper",
        buttonLabel: "Découvrir",
        to: "/services/prototyping/#",
        items: {
          itemOne: {
            img: require("../images/feature-icon-4.svg").default,
            heading: "Conception",
            subtitle: "Accélérez votre R&D avec notre équipe d’ingénieurs.",
          },
          itemTwo: {
            img: require("../images/feature-icon-5.svg").default,
            heading: "Prototypage",
            subtitle:
              "Dé-risquez vos innovations et éprouvez vos concepts grâce à notre processus itératif.",
          },
          itemThree: {
            img: require("../images/feature-icon-6.svg").default,
            heading: "Tester",
            subtitle:
              "Validez les fonctionnalités de votre produit, étape par étape.",
          },
        },
      },
      featureObjThree: {
        title: "Industrialiser, propulser",
        buttonLabel: "Découvrir",
        to: "/services/manufacturing/#",
        items: {
          itemOne: {
            img: require("../images/feature-icon-7.svg").default,
            heading: "Manufacturing",
            subtitle:
              "Sécurisez votre mass production en faisant confiance à notre réseau d’industriels qualifé.",
          },
          itemTwo: {
            img: require("../images/feature-icon-8.svg").default,
            heading: "Pilotage",
            subtitle:
              "Stratégie, qualification et pilotage des partenaires industriels : notre expertise garantit la réussite de vos  produits.",
          },
          itemThree: {
            img: require("../images/feature-icon-9.svg").default,
            heading: "Délivrez",
            subtitle:
              "Pré-série, approvisionnement, certification : quelque soit votre forecast, délivrer est notre priorité.",
          },
        },
      },
    },
    footerSection: {
      title:
        "Accélérez votre mise sur le marché grâce à nos programmes sur mesure",
      // subtitle: "Tell us more about your project with a free 30-min call.",
      buttonLabel: "Contactez-nous",
      to: "//calendly.com/martin-herrera-y/30min",
    },
  },
  services: {
    header: {
      id: "services-section",
      title: "Nos experts vous accompagnent",
      subtitle:
        "Découvrez notre offre adaptée à vos besoins hardware, de l’idéation jusqu’à la grande série.",
      img: require("../images/services-bg.png"),
    },
    headlineSection: {
      id: "services-section",
      titleOne: "Nos experts vous accompagnent à ",
      titleMark: "toutes les étapes",
      titleTwo: " de votre projet",
      subtitleOne: `Mettons un terme aux risques supposés des projets hardware ! 
        L’expérience de nos experts techniques réduit considérablement les phases de fabrication, 
        et porte les projets jusqu’à leur succès industriel.`,
      subtitleTwo: `Chez Yucca lab, nous pilotons l’industrialisation des projets les plus 
      techniques. Notre expertise vous fait gagner un temps précieux sur votre phase de R&D, 
      et vous évite les modifications coûteuses, habituellement rencontrées à l’usine.`,
      steps: {
        design: {
          name: "design",
          img: require("../images/Starting a business project-bro.svg").default,
          to: "/services/design/#",
        },
        engineering: {
          name: "R&D",
          img: require("../images/Prototyping process-amico.svg").default,
          to: "/services/engineering/#",
        },
        prototyping: {
          name: "prototypage",
          img: require("../images/Starting a business project-amico-green.svg")
            .default,
          to: "/services/prototyping/#",
        },
        manufacturing: {
          name: "industrialisation",
          img: require("../images/Manufacturing Process-rafiki.svg").default,
          to: "/services/manufacturing/#",
        },
      },
    },
    highlightsSection: {
      highlightObjOne: {
        icon: require("../images/fi-rr-diploma.svg").default,
        title: "Créons de la propriété industrielle",
        subtitle:
          "Les idées ingénieuses que nous concrétisons vous appartiennent.",
      },
      highlightObjTwo: {
        icon: require("../images/fi-rr-graduation-cap.svg").default,
        title: "Formez vos équipes avec nous",
        subtitle:
          "Nous formons vos équipes en cours de route, afin de faire monter en compétence vos équipes internes.",
      },
      highlightObjThree: {
        icon: require("../images/fi-rr-sort-amount-up-alt.svg").default,
        title: "Un process agile",
        subtitle:
          "Prototyper, tester, corriger est notre devise ; nous ne nous arrêtons pas avant d'avoir atteint la perfection.",
      },
      highlightObjFour: {
        icon: require("../images/fi-rr-users-alt.svg").default,
        title: "Toujours à vos côtés",
        subtitle:
          "Une équipe dédiée par projet vous accompagne sur le long terme.",
      },
      highlightObjFive: {
        icon: require("../images/mind-share.svg").default,
        title: "Nous aimons la complexité",
        subtitle: `Les défis les plus difficiles font naître les meilleures idées en nous. 
          Venez avec vos idées les plus folles, et nous saurons les mettre en œuvre.`,
      },
    },
    footerSection: {
      title:
        "Accélérez votre mise sur le marché grâce à nos programmes sur mesure",
      // subtitle: "Tell us more about your project with a free 30-min call.",
      buttonLabel: "Contactez-nous",
      to: "//calendly.com/martin-herrera-y/30min",
    },
    design: {
      header: {
        id: "design-section",
        title: "Sublimons votre innovation",
        subtitle:
          "Notre agence de design industriel concrétise vos projets les plus ambitieux.",
        img: require("../images/design-bg.png"),
      },
      summary: {
        id: "industrial-design",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "Design & Idéation",
        headLine: "Design industriel",
        description: `Nous créons des solutions innovantes, uniques et intuitives, 
        qui ont un impact positif et durable sur leurs utilisateurs et le monde qui les entoure.`,
        description2: `Nos designs sont épurés et simples d’utilisation. 
        Notre conception intègre vos contraintes RSE afin de concevoir une fabrication 
        respectueuse de l’environnement.`,
        imgStart: false,
        img: require("../images/Starting a business project-bro-blue.svg")
          .default,
        alt: "product design",
        darkText: true,
        tight: true,
        items: [
          "• Analyse comparative",
          "• Interviews clients",
          "• Persona analysis",
          "• Stratégie CMD & CMF",
          "• Design thinking",
          "• Design Industriel",
          "• UI & UX design",
          "• Packaging design",
          "• Identité de marque",
          "• Dévelopment produit",
          "• Ateliers d'innovation",
          "• Visualisation de concepts",
          "• Rendus HD",
          "• Modèles physiques & validation",
          "• Spécifications matières",
          "• Conception durable",
        ],
      },
      banner: {
        id: "design-banner",
        title: "Concevons ensemble les idées de demain",
        img: require("../images/design-banner.png"),
      },
      sectionOne: {
        id: "product-specifications",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "1.",
        headLine: "Spécifications produit",
        description: `Dans cette étape préliminaire à la rédaction de votre cahier 
        des charges et des spécifications de votre innovation, Yucca lab vous accompagne 
        dans l'analyse d'éléments clés :`,
        imgStart: false,
        img: require("../images/design-vignette-1.png"),
        alt: "product specifications",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
        items: [
          "• Produit de référence et des normes applicables",
          "• Produits concurrents et analogues, brevets en place",
          "• Analyse ergonomique",
          "• Enquête auprès des utilisateurs (profil du consommateur, besoin, etc)",
        ],
      },
      sectionTwo: {
        id: "creative-process",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "2.",
        headLine: "Processus créatif",
        description: `Nos équipes de designers travaillent en étroite collaboration avec 
        nos ingénieurs mécanique, afin de produire des concept innovants et réalistes. 
        La faisabilité industrielle est au coeur de notre processus d’idéation, 
        nous plaçons également l’utilisateur au centre de notre méthodologie.`,
        description2: `Notre expertise en prototypage rapide permet de réaliser des maquettes, 
        tester et itérer afin de répondre au mieux au cahier des charges et de garantir un 
        design ergonomique, répondant au mieux aux besoins de l’utilisateur.`,
        imgStart: true,
        img: require("../images/design-vignette-2.png"),
        alt: "product specifications",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionThree: {
        id: "from-sketch-to-prototype",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "3.",
        headLine: "Du croquis à la maquette",
        description: `Les outils de modélisation 3D et les techniques de prototypage 
        cosmétique nous permettent de concrétiser les concepts les plus prometteurs.`,
        description2: `L’objectif de cette étape est de donner vie à votre produit, 
        de valider ses proportions, son ergonomie, le choix des matériaux, des couleurs et 
        des textures avant de passer au processus de conception industrielle.`,
        imgStart: false,
        img: require("../images/design-vignette-3.png"),
        alt: "product specifications",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
    },
    engineering: {
      header: {
        id: "engineering-section",
        title:
          "Notre expertise mécanique au service de vos projets les plus ambitieux",
        subtitle:
          "Nous concevons, développons et industrialisons vos solutions hardware.",
        img: require("../images/engineering-bg.png"),
      },
      summary: {
        id: "mechanical-engineering",
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: "Ingénierie",
        headLine: "Ingénierie mécanique",
        description: `Yucca lab est spécialisé dans le développement de concepts mécaniques 
        innovants, élégants et robustes pour résoudre des problèmes d'ingénierie les plus 
        complexes.`,
        description2: `Quelle que soit la complexité de votre solution, notre approche 
        collaborative assure une transition en douceur de la conception à la masse production.`,
        imgStart: false,
        img: require("../images/Prototyping process-amico.svg").default,
        alt: "mechanical engineering",
        darkText: false,
        tight: false,
        items: [
          "• Mécanique, tenue des structures",
          "• Interface Utilisateur",
          "• CAO",
          "• Propriété Industrielle",
          "• Intégration de technologies",
          "• Optimisation des coûts",
          "•  Design for Manufacturing and Assembly (DFMA)",
          "• Stratégie d’outillage et de fabrication",
          "• Conception mécanique",
          "• Miniaturisation",
          "• Architecture produit",
          "• Calculs de structures",
        ],
      },
      banner: {
        id: "engineering-banner",
        title: "Conception industrielle et mécanique de votre solution",
        img: require("../images/engineering-banner.png"),
      },
      sectionOne: {
        id: "technical-audit",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "1.",
        headLine: "Audit technique",
        description: `Nous évaluons la maturité technique de votre produit, et analysons les 
        stratégies de conception et d’industrialisation les plus adaptées à votre cahier 
        des charges.`,
        description2: `En quelques jours seulement, vous recevez une liste détaillée des sujets 
        à approfondir avant de lancer un cycle d’industrialisation,  une proposition de stratégie 
        technique pour y parvenir, des recommandations éclairées sur les technologies de 
        fabrication à sélectionner, et une estimation de votre roadmap industrielle.`,
        imgStart: false,
        img: require("../images/engineering-vignette-1.png"),
        alt: "technical audit",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionTwo: {
        id: "mechanical-design",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "2.",
        headLine: "Conception mécanique",
        description: `Nos ingénieurs mécanique expérimentés s’attellent à vos conceptions 
        les plus ambitieuses. Nous travaillons de pair avec les bureaux d’études en 
        électronique, et délivrons des modèles robustes et paramétriques.`,
        description2: `Travailler ensemble, c’est l’assurance d’une conception éprouvée par 
        les simulations et le calcul, testée par des prototypes à toutes les étapes de la 
        conception, et qui évolue facilement avec les solutions techniques proposées. 
        Nous maîtrisons les logiciels de modélisation les plus avancés du marché 
        (SolidWorks, Catia, Rhinoceros...), nécessaires à toute conception professionnelle.`,
        imgStart: true,
        img: require("../images/engineering-vignette-2.png"),
        alt: "mechanical design",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionThree: {
        id: "innovation",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "3.",
        headLine: "Votre source d’innovations",
        description: `Augmentez la valeur de votre hardware avec des innovations de rupture. 
        Travailler avez Yucca lab, c’est créer ensemble des innovations technologiques brevetables. 
        Notre bureau d’études travaille main dans la main avec vos équipes, tout en concevant des 
        innovations technologiques qui répondent à votre cahier des charges.`,
        description2: `Notre philosophie, c’est de créer des solutions uniques pour chaque 
        problématique rencontrée. Nous mettons à profit nos connaissances technologiques, 
        et concevons des produits innovants, qui vous démarquent de la concurrence et vous 
        donnent un avantage compétitif.`,
        imgStart: false,
        img: require("../images/engineering-vignette-3.png"),
        alt: "innovation",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
    },
    prototyping: {
      header: {
        id: "prototyping-section",
        title: "Prototypons, testons, itérons",
        subtitle: "Un processus itératif pour vos solutions hardware.",
        img: require("../images/prototyping-bg.png"),
      },
      summary: {
        id: "prototyping",
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: "Ingénierie",
        headLine: "Prototypage",
        description: `Nous croyons aux processus itératifs : la perfection ne peut être atteinte 
        qu'avec un processus de prototypage et de tests approfondis à chaque étape de la 
        conception. La maîtrise des techniques de prototypage rapide nous permet de tester, 
        éprouver, et corriger nos concepts.`,
        description2: `En vous permettant d'obtenir rapidement des retours utilisateurs et 
        d'affiner les fonctionnalités de votre produit, notre processus de prototypage rapide 
        vous fera gagner du temps au cours du développement de votre solution. Découvrez comment 
        notre équipe expérimentée peut donner vie à vos idées.`,
        imgStart: false,
        img: require("../images/Starting a business project-amico.svg").default,
        alt: "prototype",
        darkText: false,
        tight: true,
        items: [
          "• Impression 3D",
          "• Prototypage rapide",
          "• Usinage rapide",
          "• Découpe laser",
          "• Assemblage et itérations",
          "• Gestion des fournisseurs de prototypage",
          "• Modèle cosmétique",
        ],
      },
      bannerOne: {
        id: "prototyping-banner",
        title: "Validez les fonctionnalités de votre produit",
        subtitle: `Notre méthodologie agile vous permet de tester rapidement vos concepts industriels.
        Nous prototypons en quelques jours, et itérons ensemble sur vos produits, jusqu’à atteindre vos objectifs.`,
        img: require("../images/prototyping-banner-1.png"),
      },
      listIconSection: {
        id: "prototyping-list",
        lightBg: true,
        listIconSectionOne: {
          id: "prototyping-list-1",
          img: require("../images/prototyping-icon-1.svg").default,
          topline: "1.",
          heading: "Démonstrateur",
          subtitle: `Base indispensable de tout projet qui veut rapidement récolter de précieux retours 
            utilisateurs, le démonstrateur vous permet de conceptualiser votre idée au plus vite.`,
          subtitle2: `Également appelé POC (Proof Of Concept) ou MVP (Minimum Viable Product), 
          il permet de valider au plus vite les fonctionnalités principales de votre solution.`,
        },
        listIconSectionTwo: {
          id: "prototyping-list-2",
          img: require("../images/prototyping-icon-2.svg").default,
          topline: "2.",
          heading: "Modèle cosmétique",
          subtitle: `Le modèle cosmétique est la matérialisation finale de la phase design. Il s’agit de 
            la modélisation physique de la géométrie et de l’ergonomie du produit.`,
          subtitle2: `Ce prototype permet de valider la forme, les couleurs, les textures, et le 
          choix des matériaux (Color Material Finish).`,
        },
        listIconSectionThree: {
          id: "prototyping-list-3",
          img: require("../images/prototyping-icon-3.svg").default,
          topline: "3.",
          heading: "Prototype fonctionnel",
          subtitle: `Le prototype fonctionnel est le mariage de votre Design Industriel et de 
          votre démonstrateur. Il permet de matérialiser l’ensemble des fonctionnalités de votre 
          produit, tout en épousant le langage de votre design.`,
          subtitle2: `Au Yucca lab, nous avons l’habitude de questionner au plus tôt la 
          faisabilité industrielle de votre produit : ce prototype fonctionnel nous sert également 
          à valider les problématiques d’intégration, d’assemblage...`,
        },
      },
      bannerTwo: {
        id: "prototyping-banner",
        title: "Technologies",
        img: require("../images/prototyping-banner-2.png"),
      },
      callout: {
        id: "prototyping-callout",
        lightBg: true,
        img: require("../images/prototyping-icon-4.svg").default,
        text: `Nous travaillons avec les technologies les plus récentes et performantes 
        pour réaliser nos prototypes ; un large choix de technologies d’impression 3D 
        (SLA, SLS, MJF, polyjet...) d’usinage rapide, de coulée sous vide, nous permet d’obtenir 
        des prototypes haute fidélité, et d’itérer rapidement sur les challenges d’ingénierie 
        rencontrés.`,
      },
      prototypingGrid: {
        id: "prototyping-techniques",
        lightBg: true,
        imgObjOne: {
          id: "3d-print",
          img: require("../images/3d-print.png"),
          alt: "Impression 3D",
          title: "Impression 3D",
        },
        imgObjTwo: {
          id: "lasercut",
          img: require("../images/lasercut.png"),
          alt: "Découpe laser",
          title: "Découpe laser",
        },
        imgObjThree: {
          id: "cnc",
          img: require("../images/cnc.png"),
          alt: "Usinage rapide",
          title: "Usinage rapide",
        },
        imgObjFour: {
          id: "vacuum-casting",
          img: require("../images/vacuum-casting.png"),
          alt: "Coulée sous vide",
          title: "Coulée sous vide",
        },
      },
    },
    manufacturing: {
      header: {
        id: "manufacturing-section",
        title: "Passons ensemble à la production en série",
        subtitle:
          "Nous industrialisons votre produit, jusqu'à sa mise sur le marché.",
        img: require("../images/manufacturing-bg.png"),
      },
      summary: {
        id: "manufacturing",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "Industrialisation",
        headLine: "Fabrication",
        description: `Vos produits sont uniques, et méritent une grande précision dans leur production. 
        Nous avons accumulé des années d'expérience sur le terrain : stratégie, qualification et 
        pilotage des partenaires industriels. Notre expertise garantit la réussite de vos produits.`,
        description2: `En travaillant en étroite collaboration avec un réseau d’industriels 
        qualifiés, notre équipe de gestion de projet peut coordonner la fabrication de votre 
        produit, de la pré-série à la production à grande échelle.`,
        imgStart: false,
        img: require("../images/Manufacturing Process-rafiki.svg").default,
        alt: "production line",
        darkText: true,
        tight: false,
        items: [
          "• Request for Quotation (RFQ)",
          "• Design For Manufacturing",
          "• Prescan testing",
          "• Recherche de fournisseurs",
          "• Pilotage des EMS",
          "• Optimisation produit",
          "• Inspection et management de la qualité",
          "• Planification de la production",
          "• Ramp-up & production à grande échelle",
        ],
      },
      banner: {
        id: "manufacturing-banner",
        title: "Garantissons la qualité de votre production",
        img: require("../images/manufacturing-banner.png"),
      },
      sectionOne: {
        id: "optimization",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "1.",
        headLine: "Optimisation",
        description: `Nous vous accompagnons pendant vos étape de DFM (Design For Manufacturing), 
        DFA (Design For Assembly) et DTC (Design To Cost) afin de réduire vos coûts de fabrication, 
        de simplifier l’assemblage de votre produit, et de garantir la finition de votre production.`,
        imgStart: false,
        img: require("../images/manufacturing-vignette-1.png"),
        alt: "optimisation",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
      sectionTwo: {
        id: "indus-strategy",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "2.",
        headLine: "Stratégie d’industrialisation",
        description: `Quelle que soit la taille de votre production, petite, moyenne ou grande 
        série, nous vous accompagnons dans le choix de la meilleure stratégie d’industrialisation 
        afin de contrôler au mieux le prix de revient de votre innovation.`,
        imgStart: true,
        img: require("../images/manufacturing-vignette-2.png"),
        alt: "stratégie d’industrialisation",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
        items: [
          "• Choix des matériaux",
          "• Qualité de finition",
          "• Stratégie de production",
          "• Sélection des partenaires industriels",
        ],
      },
      sectionThree: {
        id: "supplier-management",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "3.",
        headLine: "Gestion des fournisseurs",
        description: `Nos ingénieurs se chargent d’interfacer avec les partenaires industriels 
        afin de mener à bien le développement de l’outillage de fabrication, et de mettre en 
        place un plan de contrôle qualité rigoureux.`,
        description2: `Nous produisons la documentation nécessaire au bon déroulement des 
        différentes phases de votre industrialisation (EVT, DVT, PVT).`,
        imgStart: false,
        img: require("../images/manufacturing-vignette-3.png"),
        alt: "gestion des fournisseurs",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
        items: [
          "• PRD (Product Requirements Document)",
          "• Modèle 3D",
          "• Dessins 2D",
          "• BOM (Bill Of Materials)",
          "• OBA (Out of the Box Acceptancy)",
          "• CMF (Color Material Finish)",
          "• Documentation d'assemblage",
        ],
      },
      sectionFour: {
        id: "certification",
        imgWidth: "400px",
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: "4.",
        headLine: "Certification",
        description: `La certification de produits garantit la constance de la fabrication 
        d’un produit par rapport à des caractéristiques et des performances définies dans 
        une norme volontaire ou un référentiel de certification.`,
        description2: `Spécialisé dans le secteur du médical depuis de nombreuses années, 
        nous saurons vous accompagner dans le développement et la conception répondant aux 
        critères des normes ISO 13485, marquage CE...`,
        imgStart: true,
        img: require("../images/manufacturing-vignette-4.png"),
        alt: "certification",
        darkText: true,
        darkItems: true,
        tight: true,
        tightLayout: true,
      },
    },
  },
  contact: {
    heading: "Parlez-nous de votre projet",
    subtitle:
      "Veuillez remplir le formulaire ci-dessous, ou nous envoyer directement un email.",
    emailButtonText: "Voir le mail de contact",
    emailMark: "hello@yuccalab.fr",
    form: {
      title: "Contact",
      fillMessage:
        "Envoyez-nous un message via ce formulaire. Nous vous répondrons au plus vite !",
      confirmationMessage:
        "Votre message a été envoyé avec succès. Nous vous répondrons au plus vite !",
      nameLabel: "Nom",
      namePlaceholder: "Votre nom",
      emailLabel: "Email",
      emailPlaceholder: "vous@email.com",
      messageLabel: "Message",
      messagePlaceholder: "Rédigez votre message ici",
      buttonLabel: "Envoyer",
    },
  },
  agency: {
    headerSection: {
      id: "agency",
      title: "Votre agence de conception nouvelle génération",
      subtitle:
        "Nous repensons les codes du design produit et créons les produits de demain.",
      img: require("../images/agency-bg.png"),
    },
    headlineSection: {
      id: "values-headline",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      imgStart: false,
      img: require("../images/dna-square.png"),
      alt: "dna image",
      darkText: true,
      darkItems: true,
      tight: false,
      tightLayout: false,
      topLine: "Valeurs",
      headLine: "Nos valeurs",
      description: `Notre mission est de réaliser les produits physiques les plus innovants, 
      en réconciliant design industriel et ingénierie de pointe.`,
      description2: `Après des années à travailler dans le monde de la conception de produits 
      physiques, nous avons réalisé le fossé existant entre le design industriel et la conception. 
      Yucca lab rassemble les compétences de ces deux mondes, et propose une approche unique 
      au design produit.`,
      description3: `Nos conceptions sont réalisables, dès le premier jour. Avec nous, pas de design 
      impossible à fabriquer, mais des concepts simples, élégants, et compatibles avec les 
      technologies de fabrication modernes.`,
    },
    valuesSection: {
      id: "values-list",
      lightBg: true,
      textContents: [
        {
          title: {
            before: "L’",
            mark: "éco-conception",
            after: " au coeur de nos idées",
            markColor: "#8AAFF9",
          },
          description: `Chez Yucca lab, nous oeuvrons à créer un monde plus responsable, qui prend 
        en compte les enjeux climatiques dans les produits créés. Nos conceptions sont éco-conçues, 
        et nous oeuvrons tous les jours en faveur de productions locales, respectueuses des cycles 
        courts.`,
        },
        {
          title: {
            before: "Au plus près de ",
            mark: "vos équipes",
            after: "",
            markColor: "#12C9BE",
          },
          description: `Nous travaillons main dans la main avec vos équipes techniques. Nous sommes 
          convaincus que pour créer des innovations de rupture, il faut se détacher du fonctionnement 
          en vases clos des bureaux d’études. Nos consultants s’intègrent pleinement dans votre 
          organisation, et oeuvrent en synergie avec vous.`,
        },
        {
          title: {
            before: "La ",
            mark: "simplicité",
            after: " avant tout",
            markColor: "#FF9B70",
          },
          description: `Pourquoi faire simple, quand on peut faire compliqué ? Nous nous appuyons sur 
          des principes clairs pour créer des solutions robustes. Nous pensons qu’un produit qui fait 
          tout, c’est un produit qui fait tout mal ; nous nous concentrons sur l’essentiel afin 
          d’obtenir des produits faciles à industrialiser, et qui durent des années.`,
        },
        {
          title: {
            before: "",
            mark: "User centric",
            after: "",
            markColor: "#D1B1FF",
          },
          description: `Dans les produits que nous concevons, l’utilisateur est toujours mis en avant. 
          Nos conceptions sont d’abord centrées sur l’usage qui sera fait du produit : un produit à 
          succès, c’est un produit qu’on a envie d’utiliser, sans aucune friction, et qui deviendra le 
          produit vedette de votre marque.`,
        },
        {
          title: {
            before: "Learn by ",
            mark: "doing",
            after: "",
            markColor: "#8AAFF9",
          },
          description: `Nos produits fonctionnent sans failles, car ils ont commencé par des prototypes 
          imparfaits, qui mettent le doigt sur les éléments à améliorer. Nous prototypons autant que 
          possible, et très tôt dans le projet, afin de nous prémunir des défauts de conception.`,
        },
      ],
    },
    infoSectionOne: {
      id: "values-remote",
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      imgStart: true,
      img: require("../images/yucca-favicon-values.svg").default,
      alt: "yucca lab logo",
      darkText: false,
      darkItems: false,
      tight: false,
      tightLayout: true,
      headLine: "Concevoir de partout",
      description: `Notre agence est décentralisée. Nous opérons depuis toute la France, et 
      sélectionnons les profils de nos experts en fonction de leurs talents, pas de leur proximité.`,
      description2: `Nous démontrons tous les jours que le monde de demain est connecté, et peut 
      opérer à distance de manière efficace. Nos experts se rendent partout en France dès qu’un 
      assemblage est nécessaire.`,
      description3: `Travailler avec Yucca lab, c’est moins de déjeuners d’affaires, et plus de 
      tâches réalisées en temps et en heure.`,
    },
    infoSectionTwo: {
      id: "values-sites",
      lightBg: true,
      lightText: false,
      lightTextDesc: false,
      imgStart: false,
      img: require("../images/map-outline-stroke.svg").default,
      alt: "yucca lab logo",
      darkText: true,
      darkItems: true,
      tight: true,
      tightLayout: true,
      topLine: "Agence",
      headLine: "Une agence au plus près de vous",
      description: `Nous opérons depuis nos différents sites en France, afin d’être au plus près 
      de nos clients.

      Concevoir, designer, tester nécessite d’être au plus près de votre projet et de vos équipe. 
      Nos experts vous rejoignent, où que vous soyez.`,
    },
    foundersSection: {
      topLine: "Nos fondateurs",
      founders: {
        founderOne: {
          name: "Camille Kerbaul, CEO",
          description: [
            `Diplômé d’un Master en ingénierie de la santé aux Arts et Métiers ParisTech, 
            Camille lance sa carrière à Boston, au Wyss Institute for Biologically Inspired 
            Engineering à Harvard, où il se passionne pour les projets innovants et 
            interdisciplinaires, regroupant médecins, biologistes, ingénieurs. Il découvre 
            ensuite les deeptechs en rejoignant les équipes de Dreem.`,
            `Il lance en 2021 l’aventure Yucca lab, notre agence d’experts capables d’accompagner 
            les projets les plus ambitieux.`,
          ],
          img: require("../images/camille-kerbaul.png"),
        },
        founderTwo: {
          name: "Martin Herrera, COO",
          description: [
            `Formé à l’École polytechnique et à l’ETH Zürich en mécanique et physique fondamentale, 
            Martin se spécialise dans les opérations de R&D et la conception de capteurs biomédicaux 
            chez Dreem durant les premières années de sa carrière.`,
            `Il rejoint Yucca lab en Janvier 2022, optimise nos procédés d’industrialisation et 
            prend en charge les opérations de R&D réalisées pour le compte de nos clients.`,
          ],
          img: require("../images/martin-herrera.png"),
        },
      },
    },
  },
  footer: {
    sections: {
      col1: {
        title: "À propos",
        howWeWork: { name: "Notre approche", to: "/#about" },
        careers: { name: "Carrières", to: "/careers/#" },
        privacy: {
          name: "Politique de confidentialité",
          to: "/privacypolicy/#",
        },
      },
      col2: {
        title: "Contactez-nous",
        contact: { name: "Contact", to: "/contact/#" },
        intro: {
          name: "Appel d'intro",
          to: "//calendly.com/martin-herrera-y/30min",
        },
      },
      col3: {
        title: "Réseaux Sociaux",
        linkedin: {
          name: "LinkedIn",
          to: "//www.linkedin.com/company/73061624/",
        },
        instagram: { name: "Instagram", to: "//www.instagram.com/yuccalab/" },
        facebook: { name: "Facebook", to: "//www.facebook.com/yuccalab" },
      },
    },
    copyrights: { text1: "Yucca lab © ", text2: " Tous droits réservés." },
  },
};
