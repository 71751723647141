import styled from "styled-components";
import { COLORS } from "../../constants";

export const ListIconContainer = styled.div`
  color: ${({ lightBg }) => (lightBg ? COLORS.dark : COLORS.lightgrey)};
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  padding: 40px 0;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ListIconWrapper = styled.div`
  display: grid;
  z-index: 1;
  min-height: 0;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 0;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;

export const ListIconRow = styled.div`
  display: grid;
  /* grid-auto-columns: minmax(auto, 1fr); */
  grid-template-columns: 1fr 4fr;
  align-items: center;
  grid-template-areas: "col1 col2";
  margin: 20px;

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1 col1" "col2 col2";
    display: flex;
    flex-direction: column;
  }
`;

export const ListIconIconWrap = styled.div`
  display: flex;
`;

export const ListIconIconImg = styled.img`
  margin-left: auto;
  margin-right: 95px;
  width: 110px;

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 80px;
  }
`;

export const ListIconTextWrap = styled.div`
  width: 1fr;
  margin-right: 30px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const ListIconTopline = styled.p``;

export const ListIconHeading = styled.h2``;

export const ListIconSubtitle = styled.p`
  padding-bottom: 20px;
`;
