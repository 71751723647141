import React, { useState } from "react";
import { ButtonRedirect } from "../ButtonElements";

import {
  FeaturesSectionContainer,
  FeaturesSectionWrapper,
  FeatureCardContainer,
  FeatureCardWrapper,
  FeatureCardTitle,
  FeatureCardContent,
  FeatureCardBtnWrap,
  FeatureCardItemContainer,
  FeatureCardItemIcon,
  FeatureCardItemIconImg,
  FeatureCardItemTextWrap,
  FeatureCardItemHeading,
  FeatureCardItemSubtitle,
} from "./FeaturesElements";

const FeaturesSection = ({ featureObjOne, featureObjTwo, featureObjThree }) => {
  return (
    <FeaturesSectionContainer>
      <FeaturesSectionWrapper>
        <FeatureCard {...featureObjOne} />
        <FeatureCard {...featureObjTwo} />
        <FeatureCard {...featureObjThree} />
      </FeaturesSectionWrapper>
    </FeaturesSectionContainer>
  );
};

const FeatureCard = ({ title, buttonLabel, to, items }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <FeatureCardContainer>
      <FeatureCardWrapper>
        <FeatureCardTitle>{title}</FeatureCardTitle>
        <FeatureCardContent>
          <FeatureCardItem {...items.itemOne} />
          <FeatureCardItem {...items.itemTwo} />
          <FeatureCardItem {...items.itemThree} />
        </FeatureCardContent>
        <FeatureCardBtnWrap>
          <ButtonRedirect
            to={to}
            smooth="true"
            duration={500}
            spy="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary={0}
            dark={1}
          >
            {buttonLabel}
          </ButtonRedirect>
        </FeatureCardBtnWrap>
      </FeatureCardWrapper>
    </FeatureCardContainer>
  );
};

const FeatureCardItem = ({ img, heading, subtitle }) => {
  return (
    <FeatureCardItemContainer>
      <FeatureCardItemIcon>
        <FeatureCardItemIconImg src={img} />
      </FeatureCardItemIcon>
      <FeatureCardItemTextWrap>
        <FeatureCardItemHeading>{heading}</FeatureCardItemHeading>
        <FeatureCardItemSubtitle>{subtitle}</FeatureCardItemSubtitle>
      </FeatureCardItemTextWrap>
    </FeatureCardItemContainer>
  );
};

export default FeaturesSection;
