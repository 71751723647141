import { enTexts } from "../texts/enTexts";
import { frTexts } from "../texts/frTexts";

function getLocalizedTextsFromBrowserLanguage() {
  const browserlanguage = window.navigator.language.toLocaleLowerCase();
  return browserlanguage;
}

function getLocalizedTexts() {
  const language =
    "yuccalabLanguage" in localStorage
      ? localStorage.yuccalabLanguage
      : getLocalizedTextsFromBrowserLanguage();
  localStorage.yuccalabLanguage = language;
  if (language.startsWith("en")) {
    return enTexts;
  }
  return frTexts;
}

export default getLocalizedTexts;
