import styled from "styled-components";
import { COLORS } from "../../constants";

export const ListContainer = styled.div`
  color: ${({ lightBg }) => (lightBg ? COLORS.dark : COLORS.lightgrey)};
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  padding: 40px 0;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 0;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 60px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const ListTextWrap = styled.div`
  width: 1fr;
`;

export const ListTitle = styled.h2`
  font-size: 2em;
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ListMarkHeading = styled.mark`
  background: linear-gradient(
    to bottom,
    transparent 50%,
    ${({ markColor }) => (markColor ? markColor : COLORS.lilac)} 50%
  );
`;

export const ListSubtitle = styled.p`
  padding-bottom: 20px;
  text-align: justify;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
