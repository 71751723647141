import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";
import { COLORS } from "../../constants";

export const HeroFooterContainer = styled.div`
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 540px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 480px) {
    height: 460px;
  }
`;

export const HeroFooterContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroFooterImgWrap = styled.div`
  width: 107px;
  padding: 0 0 30px 0;
`;

export const HeroFooterImg = styled.img`
  width: 100%;
`;

export const HeroFooterH1 = styled.h1`
  color: ${({ lightText }) => (lightText ? COLORS.lightgrey : COLORS.deepblue)};
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroFooterH2 = styled.h2`
  color: ${({ lightText }) => (lightText ? COLORS.lightgrey : COLORS.deepblue)};
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroFooterP = styled.p`
  margin-top: 24px;
  color: ${({ lightTextDesc }) =>
    lightTextDesc ? COLORS.lightgrey : COLORS.deepblue};
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroFooterBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const Mark = styled.mark`
  color: ${({ lightText }) => (lightText ? COLORS.lightgrey : COLORS.deepblue)};
  background: linear-gradient(to bottom, transparent 50%, ${COLORS.lilac} 50%);
`;
