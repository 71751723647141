import styled from "styled-components";
import { Link } from "react-router-dom";

import { COLORS } from "../../constants";

export const PrivacyPolicyContainer = styled.div`
  background: ${COLORS.deepblue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  /* height: 1200px; */
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    background: rgba(18, 27, 58, 0.2);
    z-index: 2;
  }
`;

export const PrivacyPolicyWrapper = styled.div`
  z-index: 1;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 160px;
  margin-bottom: 80px;
  padding: 0 24px;
  justify-content: center;
  color: ${COLORS.lightgrey};
`;

export const PrivacyH2 = styled.h2`
  color: ${COLORS.coral};
  margin-bottom: 32px;
  line-height: 1.1;
  font-size: 32px;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const PrivacyH3 = styled.h3`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const PrivacyP = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
`;

export const PrivacyLink = styled(Link)`
  color: ${COLORS.white};
  text-decoration: none;

  &:hover {
    color: ${COLORS.royalblue};
    transition: 0.3s ease-out;
  }
`;
