import React from "react";
import {
  PrivacyPolicyContainer,
  PrivacyPolicyWrapper,
  PrivacyH2 as H2,
  PrivacyH3 as H3,
  PrivacyP as P,
} from "./PrivacyPolicyElements";

const PrivacyPolicy = () => {
  const contactInformation = `Yucca lab SAS
  340 montée de Chaloup
  73160 Cognin, FRANCE
  hello@yuccalab.fr

  Represented by Mr. Camille Kerbaul in his quality of President.
  camille@yuccalab.fr`;
  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyWrapper>
        <H2>Who we are</H2>
        <H3>Yucca lab</H3>
        <P>
          Yucca lab is a French SAS enterprise registered within the Chambéry
          Chamber of Commerce under SIREN number 900 666 652. Its head office is
          located at 340 montée de Chaloup, 73160 Cognin, FRANCE.
        </P>
        <H3>Web host</H3>
        <P>
          Our website address is: https://www.yuccalab.fr. It is hosted by
          Amazon Web Services whose head office is located at 31 PL DES
          COROLLES, 92400 Courbevoie, FRANCE.
        </P>
        <H2>Use of personal data collected</H2>
        <P>
          Via our website http://www.yuccalab.fr, two types of data may be
          collected:
        </P>
        <H3>Data transmitted directly</H3>
        <P>
          These data are those that you send us directly, via our contact form
          or by direct contact via our email address hello@yuccalab.fr. The
          fields "name" and "email" are mandatory in the contact form.
        </P>
        <H3>Data transmitted automatically</H3>
        <P>
          During your visits, once your consent has been given, we may collect
          “web analytics” type information relating to your browsing, the
          duration of your consultation, your IP address, your browser type and
          version. The technology used is the cookie.
        </P>
        <H3>Cookies</H3>
        <P>
          If you leave a comment on our site, you will be offered to save your
          name, email address and website in cookies. This is only for your
          convenience so that you do not have to enter this information if you
          post another comment later. These cookies expire after one year.
        </P>
        <P>
          If you go to the login page, a temporary cookie will be created to
          determine if your browser accepts cookies. It does not contain any
          personal data and will be deleted automatically when you close your
          browser.
        </P>
        <H2>Use and transmission of your personal data</H2>
        <H3>How long is your data stored</H3>
        <P>
          If you write to us via the contact form, the data will be saved for a
          maximum of 3 years. We will not add your address to a mailing list
          without your consent.
        </P>
        <P>
          If you leave a comment, the comment and its metadata are retained
          indefinitely. This allows subsequent comments to be recognized and
          approved automatically instead of leaving them in the moderation
          queue.
        </P>
        <H3>The rights you have over your data</H3>
        <P>
          You have the right to consult, modify or delete all of your personal
          data. You can also withdraw your consent to the processing of your
          data. To do so, contact us via the address below:
        </P>
        <H2>Contact information</H2>
        <P
          dangerouslySetInnerHTML={{
            __html: contactInformation.replace(/\n/g, "<br />"),
          }}
        ></P>
      </PrivacyPolicyWrapper>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
