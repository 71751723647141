import React, { useState } from "react";
import { ButtonRedirect } from "../ButtonElements";
import {
  SectorsContainer,
  SectorsContent,
  SectorContainer,
  SectorIcon,
  SectorTextContainer,
  SectorTitle,
  SectorsBtnWrapper,
  SectorsP,
  SectorsLegend,
} from "./SectorsElements";

const SectorsSection = ({
  highlightObjOne,
  highlightObjTwo,
  highlightObjThree,
  highlightObjFour,
  highlightObjFive,
  primary,
  dark,
  tagline,
  btnText,
}) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <SectorsContainer>
      <SectorsContent>
        <SectorCard {...highlightObjOne} />
        <SectorCard {...highlightObjTwo} />
        <SectorCard {...highlightObjThree} />
        <SectorCard {...highlightObjFour} />
        <SectorCard {...highlightObjFive} />
      </SectorsContent>
      <SectorsLegend>
        <SectorsP>{tagline}</SectorsP>
        <SectorsBtnWrapper>
          <ButtonRedirect
            to={"/contact"}
            smooth="true"
            duration={500}
            spy="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary={primary ? 1 : 0}
            dark={dark ? 1 : 0}
          >
            {btnText}
          </ButtonRedirect>
        </SectorsBtnWrapper>
      </SectorsLegend>
    </SectorsContainer>
  );
};

const SectorCard = ({ icon, title }) => {
  return (
    <SectorContainer>
      <SectorIcon src={icon} />
      <SectorTextContainer>
        <SectorTitle>{title}</SectorTitle>
      </SectorTextContainer>
    </SectorContainer>
  );
};

export default SectorsSection;
