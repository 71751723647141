import React from "react";
import {
  HighlightContainer,
  HighlightIcon,
  HighlightTextContainer,
  HighlightTitle,
  HighlightSubtitle,
} from "./HighlightsElements";

const HighlightCard = ({ icon, title, subtitle }) => {
  return (
    <HighlightContainer>
      <HighlightIcon src={icon} />
      <HighlightTextContainer>
        <HighlightTitle>{title}</HighlightTitle>
        <HighlightSubtitle>{subtitle}</HighlightSubtitle>
      </HighlightTextContainer>
    </HighlightContainer>
  );
};

export default HighlightCard;
