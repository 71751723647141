import React from "react";
import {
  PictureGridContainer,
  PictureGridWrapper,
  PictureGridRow,
  CellImgWrap,
  CellImg,
  CellTitle,
  CellBg,
} from "./PictureGridElements";

const PictureGridSection = ({ gridObj }) => {
  return (
    <PictureGridContainer {...gridObj}>
      <PictureGridWrapper>
        <PictureGridRow>
          <PictureCell {...gridObj.imgObjOne} />
          <PictureCell {...gridObj.imgObjTwo} />
          <PictureCell {...gridObj.imgObjThree} />
          <PictureCell {...gridObj.imgObjFour} />
        </PictureGridRow>
      </PictureGridWrapper>
    </PictureGridContainer>
  );
};

const PictureCell = ({ img, alt, title }) => {
  return (
    <CellImgWrap>
      <CellBg>
        <CellImg src={img} alt={alt} />
      </CellBg>
      <CellTitle>{title}</CellTitle>
    </CellImgWrap>
  );
};

export default PictureGridSection;
