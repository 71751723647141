import React, { useState } from "react";
import { ButtonScroll, ButtonRedirect } from "../ButtonElements";
import {
  HeroFooterContainer,
  HeroFooterContent,
  HeroFooterImgWrap,
  HeroFooterImg,
  HeroFooterH2,
  HeroFooterP,
  HeroFooterBtnWrapper,
  ArrowForward,
  ArrowRight,
  Mark,
} from "./HeroFooterElements";

const HeroFooterSection = ({
  lightBg,
  lightText,
  lightTextDesc,
  img,
  alt,
  to,
  headLine,
  subtitle,
  buttonLabel,
  primary,
  dark,
  redirect,
}) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroFooterContainer lightBg={lightBg}>
      <HeroFooterContent>
        <HeroFooterImgWrap>
          <HeroFooterImg src={img} alt={alt} />
        </HeroFooterImgWrap>
        <HeroFooterH2 lightText={lightText}>
          {headLine} <Mark lightText={lightText}>Yucca lab</Mark>
        </HeroFooterH2>
        <HeroFooterP lightTextDesc={lightTextDesc}>{subtitle}</HeroFooterP>
        <HeroFooterBtnWrapper>
          {redirect ? (
            <ButtonRedirect
              to={to}
              smooth="true"
              duration={500}
              spy="true"
              offset={-80}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary={primary ? 1 : 0}
              dark={dark ? 1 : 0}
            >
              {buttonLabel} {hover ? <ArrowForward /> : <ArrowRight />}
            </ButtonRedirect>
          ) : (
            <ButtonScroll
              to={to}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary={primary ? 1 : 0}
              dark={dark ? 1 : 0}
            >
              {buttonLabel} {hover ? <ArrowForward /> : <ArrowRight />}
            </ButtonScroll>
          )}
        </HeroFooterBtnWrapper>
      </HeroFooterContent>
    </HeroFooterContainer>
  );
};

export default HeroFooterSection;
