import styled, { css } from "styled-components";
import { COLORS } from "../../constants";

const smallTopPadding = css`
  padding-top: 40px;
`;

const bigTopPadding = css`
  padding-top: 100px;
`;

export const InfoContainer = styled.div`
  color: ${COLORS.white};
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  padding: 40px 0;

  @media screen and (max-width: 768px) {
    ${({ tight }) => (tight ? smallTopPadding : bigTopPadding)}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  min-height: ${({ tightLayout }) => (tightLayout ? "0" : "860px")};
  /* min-height: 860px; */
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  padding: ${({ tightLayout }) => (tightLayout ? "0" : "0 24px")};
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;

export const InfoBg = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    display: flex;
    flex-direction: column;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 40px;
  grid-area: col1;
  @media screen and (max-width: 768px) {
    padding: 0 39px;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

export const TopLine = styled.p`
  color: ${COLORS.cornflowerblue};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h2`
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? COLORS.lightgrey : COLORS.dark)};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? COLORS.dark : COLORS.lightgrey)};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  /* max-width: 555px; */
  max-width: ${({ imgWidth }) => (imgWidth ? imgWidth : "555px")};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 80px; */
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const ItemsWrap = styled.div`
  /* display: grid;
  grid-template-columns: 50% 50%; */
  /* height: 250px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  color: ${({ darkText }) => (darkText ? COLORS.dark : COLORS.lightgrey)};
  font-size: 16px;
`;

export const Item = styled.div`
  width: 50%;
  padding-right: 10px;
  color: ${({ darkItems }) =>
    darkItems ? COLORS.dark : COLORS.cornflowerblue};
`;
