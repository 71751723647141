import styled from "styled-components";
import { COLORS } from "../../constants";

export const IconCalloutContainer = styled.div`
  color: ${({ lightBg }) => (lightBg ? COLORS.dark : COLORS.lightgrey)};
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconCalloutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  justify-content: center;
`;

export const IconCalloutIcon = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;

export const IconCalloutImg = styled.img`
  width: 110px;
  @media screen and (max-width: 768px) {
    width: 80px;
  }
`;

export const IconCalloutText = styled.div`
  padding: 20px;
`;

export const IconCalloutParagraph = styled.p`
  text-align: center;
`;
