import React from "react";
import {
  ListContainer,
  ListTitle,
  ListMarkHeading,
  ListSubtitle,
  ListTextWrap,
  ListWrapper,
} from "./ListElements";

const ListSection = ({ id, lightBg, textContents }) => {
  return (
    <ListContainer id={id} lightBg={lightBg}>
      {textContents.map((item, index) => (
        <ListElement {...item} key={`${id}-${index}`} />
      ))}
    </ListContainer>
  );
};

const ListElement = ({ title, description }) => {
  return (
    <ListWrapper>
      <ListTextWrap>
        <ListHeading title={title} />
        <ListSubtitle>{description}</ListSubtitle>
      </ListTextWrap>
    </ListWrapper>
  );
};

const ListHeading = ({ title: { before, mark, after, markColor } }) => {
  return (
    <>
      <ListTitle>
        {before}
        <ListMarkHeading markColor={markColor}>{mark}</ListMarkHeading>
        {after}
      </ListTitle>
    </>
  );
};

export default ListSection;
