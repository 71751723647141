import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SideLanSelect,
  SideLanLink,
} from "./SidebarElements";

const Sidebar = ({
  isOpen,
  toggle,
  isRoot,
  home,
  sectors,
  services,
  agency,
  contact,
}) => {
  const toggleTop = () => {
    scroll.scrollToTop();
  };

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to={home.to}
            onClick={toggle}
            smooth={true}
            duration={500}
          >
            {home.name}
          </SidebarLink>
          <SidebarLink
            to={sectors.to}
            onClick={toggle}
            smooth={true}
            duration={500}
          >
            {sectors.name}
          </SidebarLink>
          <SidebarLink
            to={services.to}
            onClick={toggle}
            smooth={true}
            duration={500}
          >
            {services.name}
          </SidebarLink>
          <SidebarLink
            to={agency.to}
            onClick={toggle}
            smooth={true}
            duration={500}
          >
            {agency.name}
          </SidebarLink>
          <SidebarLan />
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute
            to={contact.to}
            onClick={toggleTop}
            smooth={true}
            duration={500}
          >
            {contact.name}
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

const SidebarLan = () => {
  return (
    <SideLanSelect>
      <SideLanLink
        to="/"
        onClick={() => {
          localStorage.yuccalabLanguage = "en";
          window.location.reload(true);
        }}
      >
        EN
      </SideLanLink>
      <div>/</div>
      <SideLanLink
        to="/"
        onClick={() => {
          localStorage.yuccalabLanguage = "fr";
          window.location.reload(true);
        }}
      >
        FR
      </SideLanLink>
    </SideLanSelect>
  );
};

export default Sidebar;
