import styled from "styled-components";
import { COLORS } from "../../constants";

export const CareersContainer = styled.div`
  background: ${COLORS.deepblue};
  padding: 80px 30px 0 30px;
  position: relative;
  z-index: 1;
  min-height: 860px;
  color: ${COLORS.lightgrey};
`;

export const CareersWrapper = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CareersH1 = styled.h1`
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const CareersH2 = styled.p`
  margin-top: 24px;
  font-size: 20px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
