import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import InfoSection from "../components/InfoSection";

import Footer from "../components/Footer";
import InfoHeader from "../components/InfoHeader";

import getLocalizedTexts from "../utils/getLocalizedTexts";
import ListSection from "../components/ListSection";
import Founders from "../components/FoundersSection";
import { Helmet } from "react-helmet";

const Agency = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const img = () => {
    return require("../images/Yuccalab-logo.svg").default;
  };

  const texts = getLocalizedTexts();
  const menuText = texts.menu;
  const agencyText = texts.agency;
  const AgencyHeaderObj = agencyText.headerSection;
  const AgencyHeadlineObj = agencyText.headlineSection;
  const AgencyValuesObj = agencyText.valuesSection;
  const AgencyInfoOneObj = agencyText.infoSectionOne;
  const AgencyInfoTwoObj = agencyText.infoSectionTwo;
  const AgencyFoundersObj = agencyText.foundersSection;
  const FooterObj = texts.footer;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.yuccalab.fr/agency" />
      </Helmet>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <Navbar
        toggle={toggle}
        img={img}
        smoothOpacity={false}
        home={menuText.home}
        sectors={menuText.sectors}
        discover={menuText.discover}
        services={menuText.services}
        agency={menuText.agency}
        contact={menuText.contact}
      />
      <InfoHeader {...AgencyHeaderObj} />
      <InfoSection {...AgencyHeadlineObj} />
      <ListSection {...AgencyValuesObj} />
      <InfoSection {...AgencyInfoOneObj} />
      <InfoSection {...AgencyInfoTwoObj} />
      <Founders {...AgencyFoundersObj} />
      <Footer {...FooterObj} />
    </>
  );
};

export default Agency;
