import React from "react";
import { hydrate, render } from "react-dom";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import "./index.css";
import App from "./App";
Amplify.configure(config);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
}
