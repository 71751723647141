import React from "react";
import { Heading } from "../InfoSection/InfoElements";
import {
  ReferralsContainer,
  ReferralsContent,
  PartnersWrapper,
  PartnersText,
  LogoWrap,
  PartnerLink,
  PartnerLogo,
  //   TestimonialsContent,
} from "./ReferralsElements";
import {
  PartnerOne,
  PartnerTwo,
  PartnerThree,
  PartnerFour,
  PartnerFive,
} from "./Data";

const Referrals = ({ headLine, lightBg, lightText }) => {
  return (
    <ReferralsContainer lightBg={lightBg} lightText={lightText}>
      <ReferralsContent>
        <PartnersText>
          <Heading lightText={lightText}>{headLine}</Heading>
        </PartnersText>
        <PartnersWrapper>
          <LogoWrap>
            <PartnerLink
              href="//www.hinlab.com/"
              target="_blank"
              aria-label="Hinlab"
            >
              <PartnerLogo {...PartnerOne} />
            </PartnerLink>
          </LogoWrap>
          <LogoWrap>
            <PartnerLink href="//dreem.com/" target="_blank" aria-label="Dreem">
              <PartnerLogo {...PartnerTwo} />
            </PartnerLink>
          </LogoWrap>
          <LogoWrap>
            <PartnerLink
              href="//tenaka.org/"
              target="_blank"
              aria-label="Tenaka"
            >
              <PartnerLogo {...PartnerThree} />
            </PartnerLink>
          </LogoWrap>
          <LogoWrap>
            <PartnerLink
              href="//vantagerobotics.com/"
              target="_blank"
              aria-label="Vantage Robotics"
            >
              <PartnerLogo {...PartnerFour} />
            </PartnerLink>
          </LogoWrap>
          <LogoWrap>
            <PartnerLink
              href="//www.manebiotech.com/"
              target="_blank"
              aria-label="Mane Biotech"
            >
              <PartnerLogo {...PartnerFive} />
            </PartnerLink>
          </LogoWrap>
        </PartnersWrapper>
      </ReferralsContent>
      {/* <TestimonialsContent>
        <Subtitle>Yucca lab is the best - DM</Subtitle>
      </TestimonialsContent> */}
    </ReferralsContainer>
  );
};

export default Referrals;
