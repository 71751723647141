import styled from "styled-components";
import { COLORS } from "../../constants";

export const SectorsContainer = styled.div`
  background: ${COLORS.lightgrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 340px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const SectorsContent = styled.div`
  margin: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  color: ${COLORS.dark};
  z-index: 3;

  @media screen and (max-width: 1359px) {
    margin: 50px 20px;
  }

  @media screen and (max-width: 768px) {
    margin: 20px;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const SectorContainer = styled.div`
  width: 200px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1359px) {
    margin: 20px 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: row;
    margin: 5px;
  }
`;

export const SectorIcon = styled.img`
  width: 100px;

  @media screen and (max-width: 480px) {
    width: 45px;
  }
`;

export const SectorTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

export const SectorTitle = styled.h2`
  text-align: center;
  margin: 20px 0;
  font-size: 20px;
  @media screen and (max-width: 1359px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
`;

export const SectorsLegend = styled.div``;

export const SectorsP = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const SectorsBtnWrapper = styled.div`
  margin-bottom: 40px;
`;
