import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { COLORS } from "../../constants";

export const Nav = styled.nav`
  /* background: ${({ scrollNav }) =>
    scrollNav ? COLORS.deepblue : "transparent"}; */
  background: rgba(18, 27, 58, ${({ navOpacity }) => navOpacity});
  /* background: rgba(18, 27, 58, 1); */

  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(HashLink)`
  color: ${COLORS.lightgrey};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const NavImgLogo = styled.img`
  height: 1.5rem;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: ${COLORS.lightgrey};
  }
`;

export const NavMenu = styled.ul`
  height: 80px;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-content: space-between;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;

  &:hover {
    background: rgba(18, 27, 58, 1);
  }
`;

export const NavLanSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${COLORS.lightgrey};
`;

export const NavDropdown = styled.li`
  height: 80px;
  width: 170px;

  &:hover {
    background: rgba(18, 27, 58, 1);
  }
`;

export const NavLinks = styled(HashLink)`
  color: ${COLORS.lightgrey};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 4px solid ${COLORS.turquoise};
  }
`;

export const NavLanLink = styled(HashLink)`
  color: ${COLORS.lightgrey};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 5px;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(HashLink)`
  border-radius: 50px;
  background: ${COLORS.lightgrey};
  background: linear-gradient(
    to right,
    ${COLORS.cornflowerblue},
    ${COLORS.turquoise}
  );
  white-space: nowrap;
  padding: 10px 22px;
  color: ${COLORS.dark};
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    /* transition: all 0.2s ease-in-out; */
    background: ${COLORS.turquoise};
    background: linear-gradient(
      to right,
      ${COLORS.turquoise},
      ${COLORS.cornflowerblue}
    );
    color: ${COLORS.lightgrey};
  }
`;

export const DropdownLink = styled.div`
  height: 60px;
  background: rgba(18, 27, 58, 1);
  display: ${({ dropdown }) => (dropdown ? "block" : "none")};
  &:hover {
    background: ${COLORS.cornflowerblue};
  }
`;
