import styled from "styled-components";
import { COLORS } from "../../constants";

export const HighlightsContainer = styled.div`
  background: ${COLORS.deepblue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 340px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const HighlightsBg = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const HighlightsImg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const HighlightsContent = styled.div`
  margin: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  color: ${COLORS.lightgrey};
  z-index: 3;

  @media screen and (max-width: 1359px) {
    margin: 50px 20px;
  }

  @media screen and (max-width: 768px) {
    margin: 20px;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const HighlightContainer = styled.div`
  width: 200px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1359px) {
    margin: 20px 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: row;
    margin: 5px;
  }
`;

export const HighlightIcon = styled.img`
  width: 65px;

  @media screen and (max-width: 1359px) {
    width: 35px;
  }
  @media screen and (max-width: 480px) {
    width: 25px;
  }
`;

export const HighlightTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

export const HighlightTitle = styled.h3`
  text-align: center;
  margin: 20px 0;
  font-size: 28px;
  @media screen and (max-width: 1359px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
`;

export const HighlightSubtitle = styled.p`
  text-align: center;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
