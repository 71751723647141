import React from "react";
import {
  CareersContainer,
  CareersH1,
  CareersH2,
  CareersWrapper,
} from "./CareerElements";

const CareersSection = () => {
  return (
    <CareersContainer>
      <CareersWrapper>
        <CareersH1>Open positions at Yucca lab</CareersH1>
        <CareersH2>
          We have no open positions at the moment. Make sure to follow us to get
          our last job offers.
        </CareersH2>
      </CareersWrapper>
    </CareersContainer>
  );
};

export default CareersSection;
