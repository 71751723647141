export const ReferralsObjOne = {
  id: "partners",
  lightBg: false,
  lightText: true,
  topLine: "Partners",
  headLine: "Our clients",
};

export const PartnerOne = {
  src: require("../../images/HinlabLogo.svg").default,
  alt: "Hinlab",
};

export const PartnerTwo = {
  src: require("../../images/DreemLogo.svg").default,
  alt: "Dreem",
};

export const PartnerThree = {
  src: require("../../images/TenakaLogo.svg").default,
  alt: "Tēnaka",
};

export const PartnerFour = {
  src: require("../../images/VantageLogo.png"),
  alt: "Vantage Robotics",
};

export const PartnerFive = {
  src: require("../../images/ManeLogo.png"),
  alt: "Mane Biotech",
};
