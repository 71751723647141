import React from "react";
import {
  HeroContainer,
  HeroBg,
  HeroImg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
} from "./HeroElements";

import Video from "../../videos/yuccalab-hero-animation.mp4";

const HeroSection = ({ headLine, subtitle }) => {
  return (
    <HeroContainer id="home">
      <HeroBg>
        {window.innerWidth <= 768 ? null : (
          <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        )}
        <HeroImg
          src={require("../../images/HeroCover.png")}
          alt="blue color gradient"
        ></HeroImg>
      </HeroBg>
      <HeroContent>
        <HeroH1>{headLine}</HeroH1>
        <HeroP>{subtitle}</HeroP>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
