import styled from "styled-components";
import { COLORS } from "../../constants";

export const ReferralsContainer = styled.div`
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};
  color: ${({ lightText }) => (lightText ? COLORS.lightgrey : COLORS.deepblue)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  min-height: 368px;
  position: relative;
  z-index: 1;
`;

export const ReferralsContent = styled.div`
  z-index: 3;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PartnersText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  height: 100%;
`;

export const PartnersWrapper = styled.div`
  margin: 20px 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const LogoWrap = styled.div`
  height: 30px;
  margin: 20px 40px;
  /* height: 100%; */
`;

export const PartnerLink = styled.a``;

export const PartnerLogo = styled.img`
  height: 100%;
`;

export const TestimonialsContent = styled.div`
  z-index: 3;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
