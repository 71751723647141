import React from "react";
import {
  ListIconContainer,
  ListIconHeading,
  ListIconTopline,
  ListIconIconWrap,
  ListIconIconImg,
  ListIconRow,
  ListIconSubtitle,
  ListIconTextWrap,
  ListIconWrapper,
} from "./ListIconElements";

const ListIconSection = ({
  id,
  lightBg,
  listIconSectionOne,
  listIconSectionTwo,
  listIconSectionThree,
}) => {
  return (
    <ListIconContainer id={id} lightBg={lightBg}>
      <ListIconElement {...listIconSectionOne} />
      <ListIconElement {...listIconSectionTwo} />
      <ListIconElement {...listIconSectionThree} />
    </ListIconContainer>
  );
};

const ListIconElement = ({ img, topline, heading, subtitle, subtitle2 }) => {
  return (
    <ListIconWrapper>
      <ListIconRow>
        <ListIconIconWrap>
          <ListIconIconImg src={img} />
        </ListIconIconWrap>
        <ListIconTextWrap>
          <ListIconTopline>{topline}</ListIconTopline>
          <ListIconHeading>{heading}</ListIconHeading>
          <ListIconSubtitle>{subtitle}</ListIconSubtitle>
          <ListIconSubtitle>{subtitle2}</ListIconSubtitle>
        </ListIconTextWrap>
      </ListIconRow>
    </ListIconWrapper>
  );
};

export default ListIconSection;
