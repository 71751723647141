import styled from "styled-components";
import { COLORS } from "../../constants";
import { HashLink } from "react-router-hash-link";

export const ProductsContainer = styled.div`
  min-height: 860px;
  color: ${COLORS.deepblue};
  background: ${({ lightBg }) =>
    lightBg ? COLORS.lightgrey : COLORS.deepblue};

  @media screen and (max-width: 768px) {
    padding: 80px 0;
    display: flex;
  }
`;

export const ProductsImgGrid = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 140px 0;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const ProductsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    justify-content: space-evenly;
  }
`;

export const ProductsTextWrapper = styled.div`
  max-width: 800px;
  margin-bottom: 65px;
  padding: 0 15px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1000px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const ProductsHeading = styled.h2`
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  text-align: center;
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? COLORS.lightgrey : COLORS.dark)};

  @media screen and (max-width: 530px) {
    font-size: 32px;
  }
`;

export const ProductsSubtitle = styled.p`
  text-align: center;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? COLORS.dark : COLORS.lightgrey)};
`;

export const ProductsImagesWrapper = styled.div`
  color: ${({ darkText }) => (darkText ? COLORS.dark : COLORS.lightgrey)};
  width: 75%;
  display: flex;
  justify-content: space-around;
  /* justify-content: flex-end; */
  padding-right: 10px;

  @media screen and (max-width: 820px) {
    width: 100%;
    padding-right: 0;
    padding: 0 15px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 30px 0 30px;
  }
  @media screen and (max-width: 480px) {
    padding: 0 15px 0 15px;
  }
`;

export const ProductsImgWrapper = styled.div`
  width: 25%;
  max-width: 270px;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    width: 30%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ProductsHashLink = styled(HashLink)`
  text-decoration: none;
  color: inherit;
`;

export const ProductsLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    flex-direction: ${({ imgStart }) => (imgStart ? "row" : "row-reverse")};
    margin-bottom: 50px;
  }
`;

export const ProductImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 33%;
    height: 33%;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const ProductsImg = styled.img`
  width: 70%;
  max-width: 270px;
  height: 70%;
`;

export const ProductsLegendWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${({ imgStart }) => (imgStart ? "0 0 0 20px" : "0 20px 0 0")};
    /* padding-left: 20px; */
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0;
  }
`;

export const ProductsH3 = styled.h3`
  font-size: 1rem;
  margin: 15px 0;
  padding: 0 5px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ hover, darkText }) =>
    hover ? COLORS.coral : darkText ? COLORS.dark : COLORS.lightgrey};

  @media screen and (max-width: 768px) {
    text-align: center;
    margin-top: 0;
  }

  @media screen and (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export const ProductsP = styled.p`
  font-size: 1rem;
  padding: 0 8px;
  text-align: justify;

  @media screen and (max-width: 768px) {
    text-align: justify;
  }
  @media screen and (max-width: 320px) {
    font-size: 0.8rem;
  }
`;
