import React from "react";
import {
  InfoHeaderContainer,
  InfoHeaderBg,
  InfoHeaderImg,
  InfoHeaderContent,
  InfoHeaderH1,
  InfoHeaderH2,
} from "./InfoHeaderElements";

const InfoHeader = ({ id, title, subtitle, img }) => {
  return (
    <InfoHeaderContainer id={id}>
      <InfoHeaderBg>
        <InfoHeaderImg src={img} alt="" />
      </InfoHeaderBg>

      <InfoHeaderContent>
        <InfoHeaderH1>{title}</InfoHeaderH1>
        <InfoHeaderH2>{subtitle}</InfoHeaderH2>
      </InfoHeaderContent>
    </InfoHeaderContainer>
  );
};

export default InfoHeader;
