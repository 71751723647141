import styled from "styled-components";
import { COLORS } from "../../constants";

export const FoundersSectionContainer = styled.div`
  background-color: ${COLORS.lightgrey};
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 640px) {
  }
`;

export const FoundersSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1100px;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 60px;
`;

export const FoundersCardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const FoundersTopLineWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1100px;
  padding: 0 40px;
`;

export const FoundersTopLine = styled.p`
  color: ${COLORS.cornflowerblue};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const FounderCardContainer = styled.div`
  color: ${COLORS.dark};
  max-width: 600px;
  width: 45%;
  margin: 40px;
  margin-top: 0px;
  display: flex;
  @media screen and (max-width: 840px) {
    margin: 15px;
  }
  @media screen and (max-width: 640px) {
    justify-content: center;
    width: 100%;
    padding: 40px;
    padding-top: 0px;
  }
`;

export const FounderCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FounderCardName = styled.h2`
  text-transform: uppercase;
  text-align: left;
  font-size: 24px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const FounderCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FounderCardDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  @media screen and (max-width: 840px) {
    font-size: 14px;
  }
`;

export const FounderCardImgWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;

export const FounderCardImg = styled.img`
  width: 360px;

  @media screen and (max-width: 768px) {
    width: 260px;
  }
  @media screen and (max-width: 640px) {
    width: 200px;
  }
`;
