import React from "react";
import HighlightCard from "./HighlightCard";
import {
  HighlightsBg,
  HighlightsImg,
  HighlightsContainer,
  HighlightsContent,
} from "./HighlightsElements";

const HighlightsSection = ({
  highlightObjOne,
  highlightObjTwo,
  highlightObjThree,
  highlightObjFour,
  highlightObjFive,
}) => {
  return (
    <HighlightsContainer>
      <HighlightsBg>
        <HighlightsImg src={require("../../images/highlightsBg.png")} />
      </HighlightsBg>
      <HighlightsContent>
        <HighlightCard {...highlightObjOne} />
        <HighlightCard {...highlightObjTwo} />
        <HighlightCard {...highlightObjThree} />
        <HighlightCard {...highlightObjFour} />
        <HighlightCard {...highlightObjFive} />
      </HighlightsContent>
    </HighlightsContainer>
  );
};

export default HighlightsSection;
